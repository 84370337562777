import React, { useEffect, useState } from 'react';
import { StyledAutocomplete, StyledInputBox, StyledOption, StyledOptionsBox, StyledInput } from './styles';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayFallback, arrayHasContent, filterByMatchingKey } from '../../../shared/utils';
import InputLabel from '../InputLabel';
import ErrorSpan from '../ErrorSpan';
import Spinner from '../Spinner';
import { maskInput } from '../../../shared/utils/masks';

const specialCharactersRegex = "^[\\p{L}\\p{N}\\s]+$"

const Autocomplete = ({
  label,
  error,
  name,
  value,
  onChange = () => {},
  options = [],
  valueKey = 'id',
  nameKey = 'label',
  onSelect = () => {},
  disabled,
  onKeyDown,
  readonly,
  isLoading,
  mask,
  optionFormatter,
  hideOptions,
  disableSpecialCharacters,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    const optionsArray = arrayFallback(options);

    if (!value) {
      setFilteredOptions(optionsArray);
      return;
    }

    const filtered = filterByMatchingKey(options, nameKey, value);

    if (arrayHasContent(filtered)) {
      setFilteredOptions(filtered);
      return;
    }

    setFilteredOptions(optionsArray);
  }, [value, options]);

  const handleOptionClick = optionValue => {
    const selected = options.find(option => option[valueKey] === optionValue);
    onSelect(selected);
    setShowOptions(false);
  };

  const handleChange = event => {
    const { value } = event.target;

    let validValue = value;

    if (disableSpecialCharacters) {
      const regex = new RegExp(specialCharactersRegex, 'u');
      validValue = value.split('').filter(char => regex.test(char)).join('');
    }
    event.target.value = validValue
    onChange(event);
  };

  const handleFocus = () => {
    setShowOptions(true);
  };

  const handleBlur = () => {
    setTimeout(() => setShowOptions(false), 150);
  };

  const toggleOptions = () => {
    if (disabled || readonly) return;
    setShowOptions(prev => !prev);
  };

  const getDisplayedLabel = (value) => {
    const optionsArray = arrayFallback(options)
    const selected = optionsArray.find(option => String(option[valueKey]) === String(value));
    const displayValue = selected ? selected[nameKey] : value;

    if (mask) {
      return maskInput(displayValue, mask);
    }

    return displayValue;
  };

  const handleKeyDown = event => {
    if (disabled || readonly) return
    if (onKeyDown) {
      onKeyDown(event);
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const renderOptionLabel = (option) => {
    if (optionFormatter) {
      return optionFormatter(option);
    }

    return option[nameKey]
  }

  const renderOptions = () => {
    if (isLoading) {
      return (
        <StyledOptionsBox>
          <StyledOption>
            <Spinner color="#552B85" style={{ marginLeft: 0 }} />
          </StyledOption>
        </StyledOptionsBox>
      );
    }

    if (!showOptions || hideOptions) return null;

    if (!arrayHasContent(filteredOptions)) {
      return (
        <StyledOptionsBox>
          <StyledOption onClick={() => null} style={{ color: '#8e8e8e' }}>
            Sem resultados
          </StyledOption>
        </StyledOptionsBox>
      );
    }

    return (
      <StyledOptionsBox>
        {filteredOptions.map((option, index) => (
          <StyledOption key={index} onClick={() => handleOptionClick(option[valueKey])}>
            {renderOptionLabel(option)}
          </StyledOption>
        ))}
      </StyledOptionsBox>
    );
  };

  return (
    <StyledAutocomplete error={error} {...props}>
      <InputLabel>{label}</InputLabel>
      <StyledInputBox error={error} disabled={disabled}>
        <StyledInput
          {...props}
          value={getDisplayedLabel(value)}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          name={name}
          readonly={readonly}
          readOnly={readonly}
        />
        <FontAwesomeIcon icon={showOptions ? faChevronUp : faChevronDown} onClick={toggleOptions} />
      </StyledInputBox>
      {renderOptions()}
      <ErrorSpan>{error}</ErrorSpan>
    </StyledAutocomplete>
  );
};

export default Autocomplete;
