import styled from 'styled-components';

export const StyledInfoIcon = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: ${({ color }) => color || '#fff'};
  background-color: ${({ bgColor }) => bgColor || '#000'};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  b {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 900;
    font-size: 12px;
  }
`;
