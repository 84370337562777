import React, { useEffect, useState } from 'react';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { getDadosLogin, getNomePagina } from '../../../shared/utils/Utils';
import { menus } from '../../../shared/constants/MenuConstants';
import { Alert, Autocomplete, Box, Button, Card, Checkbox, Grid, InfoIcon, Input, Select, Spinner, Tooltip } from '../../../components/new';
import { bodyworkOptions, ownerOptions, wheeledOptions, ufOptions } from '../../../shared/options';
import { Formik, Form } from 'formik';
import { unmaskValue } from '../../../shared/utils/masks';
import { create, index } from '../../../services/vehicles/index.service';
import { debounce } from '../../../shared/utils';
import { formInitialData } from './formInitialData';
import { validationSchema } from './validationSchema';
import { getDadosEmpresa } from '../../configuracoes/empresas/Empresas.service';

const PATH_MENU = menus.COD_13297;

const establishmentCode = getDadosLogin().codigoEstabelecimento || 1;

const VehicleForm = () => {
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [alert, setAlert] = useState({ open: false, message: null, variant: null });
  const [isLoading, setIsLoading] = useState(false);
  const [enterpriseCode, setEnterpriseCode] = useState(null)

  const getVehicles = debounce(async searchPlate => {
    setIsLoading(true);
    try {
      const { data } = await index(searchPlate, establishmentCode, enterpriseCode);
      setVehicleOptions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, 500);

  const sanitizeFormValues = values => {
    if (!values.veiculoProprio) {
      delete values.cnpjProprietario;
      delete values.nomeProprietario;
      delete values.rntrc;
      delete values.icrEtd;
      delete values.ufTerceiro;
      delete values.tipoProprietario;
    }

    if (values.cnpjProprietario) values.cnpjProprietario = unmaskValue(values.cnpjProprietario);
    if (values.tipoRodado !== 3) delete values.placaReboque;
    if (values.tipoProprietario) values.tipoProprietario = String(values.tipoProprietario)
    values.tara = String(values.tara)
    values.capacidade = String(values.capacidade)
    values.tipoRodado = String(values.tipoRodado)
    values.tipoCarroceria = String(values.tipoCarroceria)
    values.emprCod = enterpriseCode
    return values;
  };

  const handleSubmit = async values => {
    const sanitizedValues = sanitizeFormValues(values);
    try {
      await create(sanitizedValues);
      setAlert({ open: true, message: `Veículo ${values.id ? 'editado' : 'cadastrado'} com sucesso!`, variant: 'success' });
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: `Erro ao ${values.id ? 'editar' : 'cadastrar'} veículo.`, variant: 'error' });
    }
  };

  const handlePlateChange = ({ target }, setFieldValue) => {
    setFieldValue('placaVeiculo', target.value.toUpperCase());
    if (target.value.length) {
      getVehicles(target.value.toUpperCase());
    }
  };

  const handleSelectPlate = (selectedVehicle, setFieldValue) => {
    Object.entries(selectedVehicle).forEach(([key, value]) => {
      if (value !== undefined) {
        setFieldValue(key, value);
      }
    });
  };

  const handlePlateOnKeyDown = (event, setFieldValue, resetForm, values) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.target.value.length === 7) {
        const selected = vehicleOptions.find(({ placaVeiculo }) => placaVeiculo === event.target.value);
        if (selected) {
          handleSelectPlate(selected, setFieldValue);
        } else {
          const newFormData = { ...formInitialData, placaVeiculo: event.target.value };
          handleSelectPlate(newFormData, setFieldValue);
          setAlert({ open: true, message: 'Nenhum registro encontrado para esta placa.', variant: 'warning' });
        }
      }
    } else if (event.key === 'Backspace' && values.veiculoId) {
      event.preventDefault();
      resetForm()
    }
  };

  const closeAlert = () => {
    setAlert({ open: false, message: null, variant: null });
  };

  const getEntrepriseInfo = async () => {
    try {
      const { data } = await getDadosEmpresa()
      setEnterpriseCode(data.data.id)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getEntrepriseInfo();
  }, []);

  const handleCheckboxChange = (e, setFieldValue) => {
    setFieldValue('veiculoProprio', e.target.checked);
  };

  return (
    <>
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <Formik initialValues={formInitialData} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnBlur={false} validateOnChange={false}>
          {({ values, handleChange, errors, touched, setFieldValue, isSubmitting, resetForm }) => (
            <Form>
              <Card marginTop={2}>
                <Box justifyContent="space-between" alignItems="center">
                  <h1>Cadastro de Veículo</h1>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? <Spinner /> : 'Salvar'}
                  </Button>
                </Box>
                <Card>
                  <Grid columns={4}>
                    <Autocomplete
                      name="placaVeiculo"
                      label="Placa do Veículo"
                      placeholder="Insira a placa do veículo"
                      value={values.placaVeiculo}
                      onChange={e => handlePlateChange(e, setFieldValue, resetForm)}
                      onSelect={selected => handleSelectPlate(selected, setFieldValue)}
                      error={touched.placaVeiculo && errors.placaVeiculo}
                      maxlength={7}
                      options={vehicleOptions}
                      nameKey="placaVeiculo"
                      valueKey="placaVeiculo"
                      readonly={isSubmitting}
                      onKeyDown={e => handlePlateOnKeyDown(e, setFieldValue, resetForm, values)}
                      isLoading={isLoading}
                    />
                    <Input
                      name="descricao"
                      label="Descrição"
                      placeholder="Insira a descrição do veículo"
                      value={values.descricao}
                      onChange={handleChange}
                      error={touched.descricao && errors.descricao}
                      readonly={isSubmitting}
                    />
                    <Input
                      name="renavam"
                      label="Código renavam"
                      placeholder="Insira o código de renavam (11 dig.)"
                      value={values.renavam}
                      onChange={handleChange}
                      error={touched.renavam && errors.renavam}
                      maxlength={11}
                      readonly={isSubmitting}
                      min={0}
                    />
                    <Input
                      name="tara"
                      label="Tara em KG"
                      placeholder="Insira tara em kg"
                      value={values.tara}
                      onChange={handleChange}
                      type="number"
                      error={touched.tara && errors.tara}
                      min={0}
                      readonly={isSubmitting}
                    />
                    <Input
                      name="capacidade"
                      label="Capacidade em KG"
                      placeholder="Insira a capacidade do veículo"
                      value={values.capacidade}
                      onChange={handleChange}
                      type="number"
                      error={touched.capacidade && errors.capacidade}
                      min={0}
                      readonly={isSubmitting}
                    />
                    <Autocomplete
                      name="ufVeiculo"
                      label="UF de Licenciamento"
                      options={ufOptions}
                      placeholder="Selecione a UF de licenciamento"
                      value={values.ufVeiculo}
                      onChange={handleChange}
                      onSelect={selected => setFieldValue('ufVeiculo', Number(selected.id))}
                      error={touched.ufVeiculo && errors.ufVeiculo}
                      readonly={isSubmitting}
                    />
                    <Select
                      name="tipoRodado"
                      label="Tipo de Rodado"
                      options={wheeledOptions}
                      placeholder="Selecione o tipo de rodado"
                      value={values.tipoRodado}
                      onChange={handleChange}
                      error={touched.tipoRodado && errors.tipoRodado}
                      readonly={isSubmitting}
                    />
                    {values.tipoRodado == 3 && (
                      <Input
                        name="placaReboque"
                        label="Placa do Reboque"
                        placeholder="Insira a placa do reboque"
                        value={values.placaReboque}
                        onChange={e => setFieldValue('placaReboque', e.target.value.toUpperCase())}
                        error={touched.placaReboque && errors.placaReboque}
                        maxlength={7}
                        readonly={isSubmitting}
                      />
                    )}
                    <Select
                      name="tipoCarroceria"
                      label="Tipo de Carroceria"
                      options={bodyworkOptions}
                      placeholder="Selecione o tipo de carroceria"
                      value={values.tipoCarroceria}
                      onChange={handleChange}
                      error={touched.tipoCarroceria && errors.tipoCarroceria}
                      readonly={isSubmitting}
                    />
                    <Checkbox
                      label="Veículo de terceiros?"
                      minHeight={60}
                      checked={values.veiculoProprio}
                      onChange={e => handleCheckboxChange(e, setFieldValue)}
                      disabled={isSubmitting}
                    />

                    {values.veiculoProprio && (
                      <>
                        <Input
                          name="cnpjProprietario"
                          label="CPF/CNPJ"
                          placeholder="Insira CPF ou CNPJ"
                          value={values.cnpjProprietario}
                          onChange={handleChange}
                          error={errors.cnpjProprietario}
                          mask={unmaskValue(values.cnpjProprietario).length > 11 ? '99.999.999/9999-99' : '999.999.999-99'}
                          maxlength={18}
                          readonly={isSubmitting}
                        />

                        <Input
                          name="nomeProprietario"
                          label="Nome do Proprietário"
                          placeholder="Insira o nome do proprietário"
                          value={values.nomeProprietario}
                          onChange={handleChange}
                          error={errors.nomeProprietario}
                          readonly={isSubmitting}
                        />
                        <Input
                          name="rntrc"
                          label={
                            <Box gap={0.5} alignItems="center">
                              <span>RNTRC</span>
                              <Tooltip text="Registro Nacional de Transportadores Rodoviários de Cargas">
                                <InfoIcon />
                              </Tooltip>
                            </Box>
                          }
                          placeholder="Insira o RNTRC"
                          value={values.rntrc}
                          onChange={handleChange}
                          error={errors.rntrc}
                          readonly={isSubmitting}
                          maxlength={12}
                        />
                        <Input
                          name="icrEtd"
                          label="IE"
                          placeholder="Insira IE"
                          value={values.icrEtd}
                          onChange={handleChange}
                          error={errors.icrEtd}
                          readonly={isSubmitting}
                          minlenght={8}
                          maxlength={13}
                        />
                        <Autocomplete
                          name="ufTerceiro"
                          label="UF"
                          options={ufOptions}
                          placeholder="Selecione UF"
                          value={values.ufTerceiro}
                          onChange={handleChange}
                          onSelect={selected => setFieldValue('ufTerceiro', Number(selected.id))}
                          error={errors.ufTerceiro}
                          readonly={isSubmitting}
                        />
                        <Select
                          name="tipoProprietario"
                          label="Tipo de Proprietário ou Condutor"
                          options={ownerOptions}
                          placeholder="Selecione o tipo de proprietário"
                          value={values.tipoProprietario}
                          onChange={handleChange}
                          error={errors.tipoProprietario}
                          readonly={isSubmitting}
                        />
                      </>
                    )}
                  </Grid>
                </Card>
              </Card>
            </Form>
          )}
        </Formik>
        <Alert isOpen={alert.open} message={alert.message} variant={alert.variant} onClose={closeAlert} />
      </LinxPostos>
    </>
  );
};

export default VehicleForm;
