import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';

export class ApiVerificarStatusTrackingService {
  urlLinxCore = null;

  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  execute = async (osId) => {
    if (!osId) {
      throw new Error('Id do pedido não informado!');
    }

    try {
      // Faz a requisição para verificar o status de integração do tracking
      const resp = await this.urlLinxCore.post('/IntegraVtexTracking/VerificaStatusTracking', [osId]);
      return resp.data;
    } catch (er) {
      // Trata o erro usando o serviço de erro
      await new AxiosErrorService().execute(er);

      // Lança o erro novamente para ser tratado onde a função foi chamada
      throw er;
    }
  };
}
