import React from 'react';
import { StyledLabel } from './styles';

const InputLabel = ({ children }) => {
  if (!children) return null;

  if (typeof children === 'string') {
    return <StyledLabel>{children}</StyledLabel>;
  }

  return children;
};

export default InputLabel;
