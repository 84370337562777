import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Column, DataGrid, Export, HeaderFilter, MasterDetail, Paging, Toolbar, Item } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import { alert } from 'devextreme/ui/dialog';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { selectFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { ImprimirEmLoteComodato } from '../../../../services/Relatorios/ImprimirEmLoteComodato';
import { SessionManager } from '../../../../shared/utils/sessionManager';
import { VALUES_OS } from '../../consignado-comodato-nova-os/ValuesOsKey';
import { ApiHistoricoDePedidosService } from '../../consignado-comodato-nova-os/usercases/apiHistoricoDePedidos.service';
import { ApiRecuperarOsService } from './../usercases/ApiRecuperarOs.service';
import { ApiVerificaHabilitadoVtexTrackingService } from '../../consignado-comodato-nova-os/faturamento/usercases/ApiVerificaHabilitadoVtexTracking.service';
import { CellSaldo } from './CellSaldo';
import { CellStatus } from './CellStatus';
import styles from './Component.module.scss';
import { OsDetailGrid } from './OsDatail/OsDatail';
import { ExportarListaOsParaExcelService } from './ExportarListaOsParaExcell.service';
import { ApiIntegrarVtexTrackingService } from '../../consignado-comodato-nova-os/faturamento/usercases/ApiIntegrarVtexTracking.service';
import { ApiVerificarStatusTrackingService } from '../../consignado-comodato-lista/usercases/ApiVerificarStatusTracking.service';
import { faTruck } from '@fortawesome/free-solid-svg-icons';

import PropTypes from 'prop-types';

export function ListaOs({ setSearchingMessage, setSearching, headerFilter }) {
  const [habilitadoVtex, setHabilitadoVtex] = useState(false);
  const { values } = useSelector(selectFiltroOs);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [resolution, setResolution] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const updateColumns = () => {
      setResolution(window.innerWidth);
    };
    window.addEventListener('resize', updateColumns);
    updateColumns();
    return () => window.removeEventListener('resize', updateColumns);
  }, []);

  const onCheckboxChange = useCallback((e, rowData) => {
    const { id, status } = rowData;

    // Verifica se o status é "CONFIRMADO"
    if (true || status.toUpperCase() === 'CONFIRMADO') {
      setSelectedOrders(prev => (e.target.checked ? [...prev, id] : prev.filter(orderId => orderId !== id)));
    }
  }, []);

  useEffect(() => {

    const api = new ApiVerificaHabilitadoVtexTrackingService();
    api.execute().then(result => {
      setHabilitadoVtex(result);
    }).catch(error => {
      console.error('Erro ao verificar se está habilitado:', error);
      setHabilitadoVtex(false);
    });
  }, []);

  useEffect(() => {
    if (values && values.itens && values.itens.length) {
      setSelectedOrders([]); // Limpa a seleção de checkboxes
    }
  }, [values]);

  const integrarVtexTracking = useCallback(async () => {
    if (selectedOrders.length === 0) {
      Swal.fire('Atenção', 'Selecione pelo menos um pedido para integrar.', 'warning');
      return;
    }

    try {
      const pedidosJaIntegrados = [];

      for (const item of values.itens) {
        if (selectedOrders.includes(item.id)) {
          try {
            const resultado = await new ApiVerificarStatusTrackingService().execute(item.uId);

            if (resultado.length > 0) {
              // Adiciona os resultados detalhados com os dados relevantes
              pedidosJaIntegrados.push({
                numeroPedido: item.numeroPedido,
                resultados: resultado.map(r => ({
                  tipoIntegracao: r.tipoIntegracao,
                  data: r.data,
                })),
              });
            }
          } catch (error) {
            console.error(`Erro ao verificar integração para o pedido ${item.numeroPedido}:`, error);
          }
        }
      }

      // Se houver pedidos integrados, pede confirmação
      if (pedidosJaIntegrados.length > 0) {
        const { isConfirmed } = await Swal.fire({
          title: 'Pedidos já integrados',
          html: `
                    <p>Os seguintes pedidos já foram integrados:</p>
                    <ul>
                      ${pedidosJaIntegrados
                        .map(
                          pedido => `
                            <li>
                              Pedido ${pedido.numeroPedido}:
                              <ul>
                                ${pedido.resultados
                                  .map(
                                    r => `
                                      <li>
                                        Tipo: ${r.tipoIntegracao}, Data: ${new Date(r.data).toLocaleString('pt-BR', {
                                      day: '2-digit',
                                      month: '2-digit',
                                      year: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })}
                                      </li>

                                    `,
                                  )
                                  .join('')}
                              </ul>
                            </li>
                          `,
                        )
                        .join('')}
                    </ul>
                    <p>Deseja continuar a integração mesmo assim?</p>
                  `,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, continuar',
          cancelButtonText: 'Não, cancelar',
        });

        if (!isConfirmed) {
          return; // Sai sem prosseguir
        }
      }

      const { value: opcao } = await Swal.fire({
        title: '<h3>Selecione o tipo de integração</h3>',
        html: `
          <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px;">
            <label style="display: flex; align-items: center;">
              <input type="radio" name="integration" value="1" style="margin-right: 10px;">
              <span>📦 SOMENTE ENTREGA</span>
            </label>
            <label style="display: flex; align-items: center;">
              <input type="radio" name="integration" value="2" style="margin-right: 10px;">
              <span>🚚 SOMENTE RECOLHA</span>
            </label>
            <label style="display: flex; align-items: center;">
              <input type="radio" name="integration" value="3" style="margin-right: 10px;">
              <span>↩️ ENTREGA E RECOLHA</span>
            </label>
          </div>
        `,
        preConfirm: () => {
          const selected = document.querySelector('input[name="integration"]:checked');
          if (!selected) {
            Swal.showValidationMessage('Você precisa selecionar uma opção!');
          }
          return selected ? selected.value : null;
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      });

      if (!opcao) {
        return;
      }

      setSearching(true);
      const api = new ApiIntegrarVtexTrackingService();

      if (!values.itens) {
        throw new Error('Lista de itens não encontrada');
      }

      const pedidosSelecionados = values.itens.filter(item => selectedOrders.includes(item.id)).map(item => item.uId);

      if (pedidosSelecionados.length === 0) {
        throw new Error('Nenhum pedido válido selecionado');
      }

      const resultado = await api.execute({ pedidosSelecionados, tipoIntegracao: Number(opcao) });

      setSearching(false);

      if (Array.isArray(resultado)) {
        const integracoesComFalha = resultado.filter(r => !r.sucesso);
        if (integracoesComFalha.length > 0) {
          const mensagensErro = integracoesComFalha.map(r => `Pedido ${r.numeroPedido}: ${r.mensagemErro}`).join('\n');
          Swal.fire('Integração concluída com erros', mensagensErro, 'warning');
        } else {
          Swal.fire('Integração realizada com sucesso!', '', 'success');
          setSelectedOrders([]);
        }
      } else {
        Swal.fire('Integração realizada com sucesso!', '', 'success');
        setSelectedOrders([]);
      }
    } catch (error) {
      setSearching(false);
      console.error('Erro completo:', error);
      Swal.fire('Erro ao integrar tracking:', error.message, 'error');
    }
  }, [selectedOrders, values.itens, setSearching]);

  const onExporting = useCallback(
    async e => {
      await new ExportarListaOsParaExcelService().execute(e, 'Relatório de Pedido', headerFilter.current.filtroString(), 'Relatorio_Pedido_Comodato', worksheet => {
        worksheet.getColumn(14).numFmt = '"R$" #,##0.00';
        worksheet.getColumn(15).numFmt = '"R$" #,##0.00';
        worksheet.getColumn(1).style = { font: { bold: false, size: 8 }, column: { width: 20 } };
        worksheet.getColumn(10).style = { font: { bold: false, size: 8 }, column: { width: 20 } };
        worksheet.getColumn(11).style = { font: { bold: false, size: 8 }, column: { width: 800 } };
      });

      e.cancel = true;
    },
    [headerFilter],
  );

  const onRowClick = useCallback(
    async e => {
      try {
        setSearchingMessage('Carregando Pedido...');
        setSearching(true);
        const os = await new ApiRecuperarOsService().execute(e.row.data.id);
        if (!os) {
          alert('Não foi possível recuperar o Pedido.', 'Atenção!');
          setSearching(false);
          return;
        }
        const hist = await new ApiHistoricoDePedidosService().execute(os.cliente.id);
        os.historicoDePedidos = hist;
        new SessionManager().saveEncryptedData(VALUES_OS, os);
        setSearching(false);
        history.push('/operacoes/pedidos/novo');
      } catch (error) {
        setSearching(false);
        Swal.fire('Erro', error.message, 'error');
      }
    },
    [setSearching, setSearchingMessage, history],
  );

  const detailRender = useCallback(props => {
    const expanded = !props.value;
    const detailClick = () => {
      if (props.value) {
        props.component.collapseRow(props.key);
      } else {
        props.component.expandRow(props.key);
      }
    };

    return <span onClick={detailClick}>{expanded ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>;
  }, []);

  const onClickImpressaoLote = useCallback(
    async e => {
      try {
        setSearchingMessage('Gerando Impressão...');
        setSearching(true);
        const download = new ImprimirEmLoteComodato();
        await download.execute(e.row.data.uId, e.row.data.numeroPedido);
        setSearching(false);
      } catch (error) {
        setSearching(false);
        Swal.fire('Erro', error.message, 'error');
      }
    },
    [setSearching, setSearchingMessage],
  );

  return (
    <div className={`${styles.listaOs} content-wrapper`} style={{ position: 'relative', top: '-20px' }}>
      <DataGrid
        rowAlternationEnabled={true}
        dataSource={(values || []).itens}
        noDataText="Sem dados"
        keyExpr="id"
        id="dataGrid"
        allowColumnReordering={true}
        remoteOperations={true}
        onExporting={onExporting}
        className={`${styles.middle}`}
      >
        <Toolbar>
          <Item
            location="after"
            widget="dxButton"
            options={{
              visible: habilitadoVtex,
              icon: 'fas fa-sync',
              text: `Integrar Tracking (${selectedOrders.length})`,
              onClick: integrarVtexTracking,
              disabled: selectedOrders.length === 0,
              elementAttr: {
                class: styles['toolbar-button'],
              },
            }}
          />
          <Item name="exportButton" location="after" />
        </Toolbar>
        <MasterDetail enabled={true} component={OsDetailGrid} />
        <Column type="detailExpand" cellRender={detailRender} />
        <Column dataField="status" width={50} caption="Status" cellRender={CellStatus} className={` p-0 m-0`}>
          <HeaderFilter groupInterval={10000} />
        </Column>
        <Column
          caption="Int. Tracking"
          width={80}
          visible={habilitadoVtex}
          cellRender={({ data }) => {
            /*
                  EM REUNIÃO COM O TIME CBE, FICOU DEFINIDO QUE NÃO MAIS SERÁ NECESSÁRIA A NOTA ESTAR EMITIDA PARA QUE O PEDIDO SEJA INTEGRADO.
                const isStatusConfirmado = (data.status.toUpperCase() === 'CONFIRMADO') && (data.statusNF===2) && (data.tipoFreteID===2|| data.tipoFreteID===3); // Verifica se é 'CONFIRMADO'
                */

            const checkIntegraEnable = data.status.toUpperCase() !== 'EM ABERTO' && data.status.toUpperCase() !== 'CANCELADO' && (data.tipoFreteID === 2 || data.tipoFreteID === 3); // Verifica se é 'CONFIRMADO'

            return (
              <input
                type="checkbox"
                checked={selectedOrders.includes(data.id)}
                onChange={e => onCheckboxChange(e, data)}
                disabled={!checkIntegraEnable} // Habilita apenas se o status for diferente de EM ABERTO
              />
            );
          }}
        />
        <Column dataField="numeroPedido" width={60} dataType="number" caption="Pedido"></Column>
        <Column dataField="nFes" dataType="text" width={resolution > 1000 ? 80 : 0} caption={'NF-es'}></Column>
        <Column dataField="vTexId" dataType="text" caption="VTEX" width={resolution > 1250 ? 130 : 0}></Column>
        <Column dataField="origem" dataType="text" width={60} widget={resolution > 800 ? 50 : 0} caption="Origem"></Column>
        <Column dataField="nomeCliente" dataType="text" caption="Cliente" minWidth={200}></Column>
        <Column dataField="telefoneCliente" dataType="text" caption="Telefone" width={100}></Column>
        <Column dataField="logradouro" dataType="text" width={resolution > 1250 ? undefined : 0} caption="Endereço Entrega"></Column>
        <Column dataField="bairro" width={resolution > 1250 ? undefined : 0} caption="Bairro" dataType="text"></Column>
        <Column dataField="municipio" caption="Cidade" dataType="text" width={resolution > 1200 ? undefined : 0}></Column>
        <Column dataField="uf" caption="UF" dataType="text" width={resolution > 1200 ? undefined : 0}></Column>
        <Column dataField="cep" caption="CEP" dataType="text" width={resolution > 1200 ? undefined : 0}></Column>
        <Column dataField="enderecoRecolha" allowExporting={true} width={0} caption="Endereço Recolha" dataType="text"></Column>
        <Column dataField="enderecoRecolhaBairro" allowExporting={true} width={0} caption="Endereço Recolha Bairro" dataType="text"></Column>
        <Column dataField="enderecoRecolhaMunicipio" allowExporting={true} width={0} caption="Endereço Recolha Municipio" dataType="text"></Column>
        <Column dataField="enderecoRecolhaUf" allowExporting={true} width={0} caption="Endereço Recolha UF" dataType="text"></Column>
        <Column dataField="enderecoRecolhaCep" allowExporting={true} width={0} caption="Endereço Recolha CEP" dataType="text"></Column>
        <Column dataField="dataPedido" caption={'Data Pedido'} dataType="date" width={100} format={'dd/MM/yyyy HH:mm'}></Column>
        <Column dataField="dataEvento" caption={'Data Evento'} width={resolution > 1250 ? 100 : 0} dataType="date" format={'dd/MM/yyyy HH:mm'}></Column>
        <Column dataField="dataEntregaRetirada" width={resolution > 1200 ? undefined : 0} caption="Ent./Ret." dataType="date" format={'dd/MM/yyyy'} />
        <Column dataField="entregaRetiradaHoraDe" width={0} caption="de" dataType="text" />
        <Column dataField="entregaRetiradaHoraAte" width={0} caption="até" dataType="text" />
        <Column dataField="dataRecolhaDevolucao" width={resolution > 1200 ? undefined : 0} dataType="date" format={'dd/MM/yyyy'} />
        <Column dataField="recolhaDevolucaoHoraDe" width={0} caption="de" dataType="text" />
        <Column dataField="recolhaDevolucaoHoraAte" width={0} caption="até" dataType="text" />
        <Column caption={'Saldo'} width={resolution > 850 ? 80 : 0} dataField="saldo" alignment={'right'} cellRender={CellSaldo} />
        <Column dataField="totalGeral" caption={'Total Geral'} format="R$ #,##0.00" />
        <Column type="buttons" width={60} caption="Ações">
          <Button hint="Editar Pedido" icon="edit" onClick={onRowClick} />
          <Button hint="Imprimir Tudo" icon="print" onClick={onClickImpressaoLote} />
        </Column>
        <Column
          caption="Status Tracking"
          width={90}
          visible={habilitadoVtex} // Apenas se habilitado para VTEX
          cellRender={({ data }) => {
            const isIntegrated = data.statusTracking === 'Sim';
            return (
              <span style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '14px' }}>
                <FontAwesomeIcon
                  icon={faTruck}
                  style={{
                    color: isIntegrated ? 'green' : 'red',
                    fontSize: '12px',
                  }}
                />
                <span style={{ fontSize: 'inherit', fontWeight: 'normal' }}>{isIntegrated ? 'Sim' : 'Não'}</span>
              </span>
            );
          }}
        />
        <Paging defaultPageSize={20} defaultPageIndex={0} /> {/* Shows the second page */}
        <Export
          enabled={true}
          texts={{ exportAll: 'Exporte tudo para {0}', exportSelectedRows: 'Exporte a seleção para {0}', exportTo: 'Exporte para {0}' }}
          formats={['xlsx']}
          keepColumnWidths={true}
          fileName={'Ralatório de Pedido Comodato'}
        />
      </DataGrid>
    </div>
  );
}
ListaOs.propTypes = {
  setSearchingMessage: PropTypes.func.isRequired,
  setSearching: PropTypes.func.isRequired,
  headerFilter: PropTypes.object.isRequired,
};
