import styled from "styled-components";

export const StyledChip = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: ${({ bgColor }) => bgColor || "gray"};
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ textColor }) => textColor || "#fff"};
  cursor: default;
  user-select: none;
  text-align: center;
  justify-content: center;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;