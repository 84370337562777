import React from 'react';
import { StyledTab, StyledTabs } from '../../../../../components/new/Tabs/styles';
import { useHistory } from 'react-router-dom';

const Tabs = () => {
  const history = useHistory();
  const pathname = window.location.pathname;

  const tabs = [
    {
      label: 'Geração',
      path: '/nf-e/MDFe/Geracao',
    },
    {
      label: 'Consulta',
      path: '/nf-e/MDFe/Consulta',
    },
  ];

  const handleClick = path => {
    if (path === pathname) return;

    history.push(path);
  };

  return (
    <StyledTabs>
      {tabs.map(({ label, path }) => (
        <StyledTab isActive={pathname === path} onClick={() => handleClick(path)}>
          {label}
        </StyledTab>
      ))}
    </StyledTabs>
  );
};

export default Tabs;
