import { getDadosLogin, getDadosUsuario } from '../../../../../shared/utils/Utils';

const establishmentCode = getDadosLogin().codigoEstabelecimento || 1;

const todaysDate = new Date().toISOString().split('T')[0]

export const formInitialData = {
  numeroMdfe: '',
  serie: '',
  dataEmissao: todaysDate,
  chave: '',
  cepOrigem: '',
  estadoOrigem: '',
  municipioOrigem: '',
  cepDestino: '',
  estadoDestino: '',
  municipioDestino: '',
  ufOrigem: '',
  cidadeOrigem: '',
  ufDestino: '',
  cidadeDestino: '',
  numeroNota: '',
  serieNota: '',
  dataIni: '',
  dataFim: '',
  notasRef: [],
  qtdNfeVinculadas: '',
  valorCarga: '',
  pesoCarga: '',
  unMedida: '01',
  placaVeiculo: '',
  descricao: '',
  renavam: '',
  tara: '',
  capacidade: '',
  ufLicenciamento: '',
  tipoRodado: '',
  tipoCarroceria: '',
  cpfMotorista: '',
  nomeMotorista: '',
  estaCod: establishmentCode,
  emprCod: '',
  infAdc: '',
  responsavelUltimaAcao: '',
  chave: ''
};
