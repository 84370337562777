import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class RegraParcelamentoService {
  static instance = null;
  static getInstance() {
    if (!RegraParcelamentoService.instance) {
      RegraParcelamentoService.instance = new RegraParcelamentoService();
    }
    return RegraParcelamentoService.instance;
  }

  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  listarRegras = async (payload) => {
    try {
      const response = await this.urlLinxCore.get(
        `/EstruturaCartoes/ListarParcelamentoTaxaValor?valorPagamento=${payload.valorPagamento}&bandCod=${payload.bandCod}&redeCod=${payload.redeCod}&prReCod=${payload.prReCod}`,
      );

      return response.data.data || [];
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  };
}
