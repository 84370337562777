import styled from 'styled-components';

export const StyledTable = styled.div`
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 8px;
`;

export const StyledTableHead = styled.div`
  background-color: #5c2e90;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 0 1rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const StyledTableRow = styled.div`
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 0 1rem;
`;

export const StyledTableCell = styled.div`
  flex: ${({ flex }) => flex || 1};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align || 'center'};
  padding: ${({ padding }) => `${padding || 0}rem`};
`;

export const StyledTablePagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-size: 0.85rem !important;
  }
`;
