import styled from 'styled-components';

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns || 1}, 1fr);
  gap: ${({ gap }) => `${gap || 1}rem`};
  width: 100%;

  > * {
    width: 100%;
    box-sizing: border-box;
  }
`;
