import { getDadosLogin } from "../../../shared/utils/Utils";

const establishmentCode = getDadosLogin().codigoEstabelecimento || 1;

export const formInitialData = {
  placaVeiculo: '',
  placaReboque: '',
  descricao: '',
  renavam: '',
  tara: '',
  capacidade: '',
  ufVeiculo: '',
  tipoRodado: '',
  tipoCarroceria: '',
  cnpjProprietario: '',
  nomeProprietario: '',
  rntrc: '',
  icrEtd: '',
  ufTerceiro: '',
  tipoProprietario: '',
  veiculoProprio: false,
  estaCod: establishmentCode,
  emprCod: '',
};
