import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';
import { getDadosLogin } from '../../../../shared/utils/Utils';

export class ApiRecuperaAtualSolResService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }


  execute = async () => {
    let usuario = getDadosLogin();

    let estaCod = usuario.codigoEstabelecimento;

    if(!estaCod)throw new Error('Código do estabelecimento não informado!');
    try {
      const resp = await this.urlLinxCore.get(`/SolReserva/BuscarSolicitacaoAtual/${estaCod}`);

      return (resp.data || {});
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  };

  normalizeDateString(obj) {
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        if (!element) continue;
        if (Array.isArray(element)) {
          //console.log('Name/Array', `${key} / ${element}`);
        }
        if (element.constructor.name === 'Date') {
          //console.log('Name/Date', `${key} / ${element}`);
        }
        if (element.constructor.name === 'String' && element.match(/^[0-9]{4}-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9]|:[0-9][0-9][0-9]$/)) {
          //console.log('Name/String', `${key}`, element);
          obj[key] += 'Z';
        }
      }
    }
  }


}
