import styled from 'styled-components';

export const StyledIconButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'}
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.15); 
  }

  & > svg {
    width: ${({ size }) => `${size || 1}rem`};
    height: ${({ size }) => `${size || 1}rem`};
    fill: currentColor;
  }
`;
