import React from 'react';
import { StyledCheckbox } from './styles';
import InputLabel from '../InputLabel';

const Checkbox = ({ label, onChange = () => {}, disabled, ...props }) => {
  return (
    <StyledCheckbox {...props}>
      <input type="checkbox" onChange={onChange} {...props} disabled={disabled} />
      <InputLabel>{label}</InputLabel>
    </StyledCheckbox>
  );
};

export default Checkbox;
