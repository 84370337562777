import React from 'react';
import { StyledDialog, DialogOverlay, DialogContent, DialogTitle, DialogActions } from './styles';
import Button from '../Button';
import { arrayHasContent } from '../../../shared/utils';

const Dialog = ({ isOpen, setIsOpen, title, buttons, children, ...props }) => {
  if (!isOpen) return null;

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <DialogOverlay onClick={closeModal}>
      <StyledDialog {...props}>
        <DialogContent>
          {title && <DialogTitle>{title}</DialogTitle>}
          <div>{children}</div>
          {arrayHasContent(buttons) && (
            <DialogActions>
              {buttons.map(({ label, onClick, ...props }, index) => (
                <Button key={index} onClick={onClick} variant={index === 0 ? 'outlined' : 'contained'} {...props}>
                  {label}
                </Button>
              ))}
            </DialogActions>
          )}
        </DialogContent>
      </StyledDialog>
    </DialogOverlay>
  );
};

export default Dialog;
