import React, { useEffect, useState } from 'react';
import { StyledOption, StyledOptionsBox, StyledSelect, StyledSelectBox } from './styles';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputLabel from '../InputLabel';
import ErrorSpan from '../ErrorSpan';
import { arrayHasContent } from '../../../shared/utils';

const Select = ({ label, name, value, options = [], placeholder = 'Selecione uma opção', onChange = () => {}, valueKey = 'id', nameKey = 'label', error, disabled, readonly, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    const selected = options.find(option => String(option[valueKey]) === String(value)) || null;
    setSelectedOption(selected);
  }, [value, options, valueKey]);

  const handleOptionClick = optionValue => {
    const selected = options.find(option => String(option[valueKey]) === String(optionValue));
    setSelectedOption(selected);
    onChange(name ? { target: { name, value: optionValue } } : optionValue);
    setShowOptions(false);
  };

  const toggleOptions = () => {
    if (disabled || readonly) return;
    setShowOptions(prev => !prev);
  };

  return (
    <StyledSelect {...props}>
      <InputLabel>{label}</InputLabel>
      <StyledSelectBox error={error} disabled={disabled} onClick={toggleOptions}>
        {selectedOption ? <span>{selectedOption[nameKey]}</span> : <span className="placeholder">{placeholder}</span>}
        <FontAwesomeIcon icon={showOptions ? faChevronUp : faChevronDown} />
      </StyledSelectBox>
      {showOptions && arrayHasContent(options) && (
        <StyledOptionsBox>
          {options.map((option, index) => (
            <StyledOption key={index} onClick={() => handleOptionClick(option[valueKey])}>
              {option[nameKey]}
            </StyledOption>
          ))}
        </StyledOptionsBox>
      )}
      <ErrorSpan>{error}</ErrorSpan>
    </StyledSelect>
  );
};

export default Select;
