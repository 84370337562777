import React from 'react';
import { CloseButton, StyledChip } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const Chip = ({ label, bgColor, onDelete }) => {
  return (
    <StyledChip bgColor={bgColor}>
      {label}
      {onDelete && (
        <CloseButton onClick={onDelete}>
          <FontAwesomeIcon icon={faX} />
        </CloseButton>
      )}
    </StyledChip>
  );
};

export default Chip;
