import styled from 'styled-components';

export const StyledTooltip = styled.div`
 position: relative;

  & .tooltip-content {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    padding: 16px;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    max-height: 0px;
    transition: 0.4s ease all;
    border-radius: 5px;
    z-index: 999;
    right: auto;

    &::before {
      content: "";
      position: absolute;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid rgba(0, 0, 0, 0.8);
      width: 0;
      height: 0;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
    }

  }
  
  &:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
    min-height: 50px;
    width: max-content
  }
`;
