import axios from 'axios';
import { getUrl } from '../../shared/utils/Utils';
import { TOKEN_PASS_AXIOS } from '../../shared/constants/Const';
import { BASE_URL_GATEWAY } from '../../shared/constants/GatewayUrlConstant';
import { getToken } from '../../authentication/Auth';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/Core/api/MDFE`,
  headers: {
    'Content-Type': 'application/json',
  },
});

urlLinxCore.interceptors.request.use(async config => {
  config.headers.Accept = 'application/json';

  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export const fetchFiscalNotes = async (params) => {
  return await urlLinxCore.get('/MDFERetornarNotas', { params });
};

export const fetchMDFe = async (params) => {
  return await urlLinxCore.get('/MDFEConsulta', { params });
};

export const createMDFe = async (params) => {
  return await urlLinxCore.post('/MDFEGeracao', params);
}

export const fetchMDFeXML = async (params) => {
  return await urlLinxCore.get('/GerarXml', { params });
}

export const fetchMDFeList = async (params) => {
  return await urlLinxCore.get('/MDFEConsultaPeriodo', { params });
}

export const createMDFeEvent = async (params) => {
  return await urlLinxCore.get('/GerarEvento', { params });
}

export const downloadMDFeXML = async (params) => {
  return await urlLinxCore.get('/DownloadXml', { params });
};
