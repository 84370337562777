import React from 'react';
import { StyledTooltip } from './styles.js';

const Tooltip = ({ text, children }) => {
  return (
    <StyledTooltip>
      {children}
      <div className="tooltip-content">{text}</div>
    </StyledTooltip>
  );
};

export default Tooltip;
