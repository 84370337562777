import * as Yup from 'yup';
import { validateCPF, validateCNPJ, validateBrazilianPlate, validateNumericCode } from '../../../shared/utils/schemaValidation';
import { unmaskValue } from '../../../shared/utils/masks';
import { ufOptions } from '../../../shared/options';

const validateOwnerCPFOrCNPJ = value => {
  const unmaskedValue = unmaskValue(value);
  return unmaskValue(value).length < 12 ? validateCPF(unmaskedValue) : validateCNPJ(unmaskedValue);
};

const validateUF = ufId => {
  return ufOptions.some(({ id }) => String(id) === String(ufId));
};

export const validationSchema = Yup.object().shape({
  placaVeiculo: Yup.string()
    .required('Campo obrigatório')
    .test('placaVeiculo validation', 'Modelo de placa inválido', validateBrazilianPlate),
  descricao: Yup.string().required('Campo obrigatório'),
  renavam: Yup.string()
    .required('Campo obrigatório')
    .test('renavam validation', 'Campo deve conter 11 dígitos numéricos', code => validateNumericCode(code, 11)),
  tara: Yup.number()
    .required('Campo obrigatório')
    .positive('Deve ser um número positivo'),
  capacidade: Yup.number()
    .required('Campo obrigatório')
    .positive('Deve ser um número positivo'),
  ufVeiculo: Yup.string()
    .required('Campo obrigatório')
    .test('UF Validation', 'UF Inválida', validateUF),
  tipoRodado: Yup.string().required('Campo obrigatório'),
  placaReboque: Yup.string().when('tipoRodado', {
    is: '3',
    then: () =>
      Yup.string()
        .required('Campo obrigatório')
        .test('placaReboque validation', 'Modelo de placa inválido', validateBrazilianPlate),
  }),
  tipoCarroceria: Yup.string().required('Campo obrigatório'),
  veiculoProprio: Yup.boolean(),
  cnpjProprietario: Yup.string().when('veiculoProprio', {
    is: true,
    then: () =>
      Yup.string()
        .required('Campo obrigatório')
        .test('CPF/CNPJ validation', 'CPF ou CNPJ inválido', validateOwnerCPFOrCNPJ),
  }),
  nomeProprietario: Yup.string().when('veiculoProprio', {
    is: true,
    then: () => Yup.string().required('Campo obrigatório'),
  }),
  rntrc: Yup.string().when('veiculoProprio', {
    is: true,
    then: () =>
      Yup.string()
        .required('Campo obrigatório')
        .test('rntrc validation', 'Campo deve ser numérico', code => validateNumericCode(code)),
  }),
  icrEtd: Yup.string().when('veiculoProprio', {
    is: true,
    then: () =>
      Yup.string()
        .required('Campo obrigatório')
        .test('icrEtd validation', 'Campo deve ser numérico', code => validateNumericCode(code)),
  }),
  ufTerceiro: Yup.string().when('veiculoProprio', {
    is: true,
    then: () => Yup.string().required('Campo obrigatório'),
  }),
  tipoProprietario: Yup.string().when('veiculoProprio', {
    is: true,
    then: () => Yup.string().required('Campo obrigatório'),
  }),
});
