import React, { useRef, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BuscarProdutoFiltro } from './busca-produto/buscarProduto-filtro';
import { DropboxFormasPagamentos } from '../DropboxFormasPagamento';
import { FloatDateInput } from '../FloatDateInput';
import { FloatTimeInput } from '../FloatTimeInput';
import { selectFiltroOs, useFiltroOs } from '../../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { useSelector } from 'react-redux';
import { DropboxTiposDeFrete } from '../DropboxTiposDeFrete';
import { DropboxStatusIntegracaoTracking } from '../DropboxStatusIntegracaoTracking';
import { ApiVerificaHabilitadoVtexTrackingService } from '../../../consignado-comodato-nova-os/faturamento/usercases/ApiVerificaHabilitadoVtexTracking.service';

export default function FiltroAvancado(props) {
  const { values } = useSelector(selectFiltroOs);
  const { changeValue } = useFiltroOs();
  const refProduto = useRef();

  // Estado para armazenar o valor de habilitadoVtex
  const [habilitadoVtex, setHabilitadoVtex] = useState(false);

  // useEffect para buscar o valor assíncrono
  useEffect(() => {
    const fetchHabilitadoVtex = async () => {
      try {
        const resultado = await new ApiVerificaHabilitadoVtexTrackingService().execute();
        setHabilitadoVtex(resultado); // Atualiza o estado com o valor retornado
      } catch (error) {
        console.error('Erro ao verificar habilitadoVtex:', error);
      }
    };

    fetchHabilitadoVtex();
  }, []); // Executa apenas uma vez após a montagem

  return (
    <Row className="m-0 p-0">
      <Col md={6}>
        <Row className="m-0 p-0">
          <Col md={12} className="ml-1">
            <BuscarProdutoFiltro ref={refProduto} tipoPedidoId={-1} title="Selecione o Produto" />
          </Col>
          <Col md={12}>
            <DropboxFormasPagamentos />
          </Col>
          <Col md={12}>
            <DropboxTiposDeFrete />
          </Col>
          {habilitadoVtex && ( // Renderiza condicionalmente se habilitadoVtex for true
            <Col md={12} style={{ marginTop: '-20px' }}>
              <DropboxStatusIntegracaoTracking />
            </Col>
          )}
        </Row>
      </Col>
      <Col md={6}>
        <Row className="m-0 p-0">
          <Col md={12} className="m-0 p-0 pl-1 d-flex">
            <FloatDateInput
              isMinTime={true}
              displayFormat="dd/MM/yyyy"
              placeholder="de:"
              label="Data do Faturamento"
              value={values.dataFaturamentoDe}
              id="dataFaturamentoDe"
              onChange={changeValue}
            />
            <FloatTimeInput
              placeholder="de:"
              label="Hora"
              value={values.dataFaturamentoDe}
              id="dataFaturamentoDe"
              onChange={changeValue}
            />
          </Col>
          <Col md={12} className="m-0 p-0 pl-1 d-flex">
            <FloatDateInput
              isMinTime={false}
              displayFormat="dd/MM/yyyy"
              label=""
              placeholder="até:"
              value={values.dataFaturamentoAte}
              id="dataFaturamentoAte"
              onChange={changeValue}
            />
            <FloatTimeInput
              placeholder="até:"
              label="Hora"
              value={values.dataFaturamentoAte}
              id="dataFaturamentoAte"
              onChange={changeValue}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
