import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';
import { getDadosLogin, getNomePagina } from '../../../../shared/utils/Utils';

export class ApiBuscarDiaHoraEncerramentoSolicitacaoService {
  urlLinxCore = null;

  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  execute = async (filtros = {}) => {
    let usuario = getDadosLogin();
    let estaCod = parseInt(usuario.codigoEstabelecimento, 10);

    if (!estaCod) throw new Error('Código do estabelecimento não informado!');

    // Adiciona o estaCod e um timestamp aos filtros
    filtros = {
      ...filtros,
      estaCod,
      _timestamp: Date.now()
    };

    try {
      const resp = await this.urlLinxCore.get('/SolReserva/BuscarDataProximoEncerramento/', {
        params: filtros,
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      console.log('ApiBuscarDiaHoraEncerramentoSolicitacaoService', resp.data);
      return resp.data || [];
    } catch (er) {
      await new AxiosErrorService().execute(er);
      throw er;
    }
  };

  normalizeDateString(obj) {
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        if (!element) continue;
        if (Array.isArray(element)) {
          //console.log('Name/Array', `${key} / ${element}`);
        }
        if (element.constructor.name === 'Date') {
          //console.log('Name/Date', `${key} / ${element}`);
        }
        if (element.constructor.name === 'String' && element.match(/^[0-9]{4}-[0-1][0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9](?:\.[0-9]{1,3})?$/)) {
          //console.log('Name/String', `${key}`, element);
          obj[key] += 'Z';
        }
      }
    }
  }
}
