import axios from 'axios';
import { getUrl } from '../../shared/utils/Utils';
import { TOKEN_PASS_AXIOS } from '../../shared/constants/Const';
import { BASE_URL_GATEWAY } from '../../shared/constants/GatewayUrlConstant';
import { getToken } from '../../authentication/Auth';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/Core/api/MDFE`,
  headers: {
    'Content-Type': 'application/json',
  },
});

urlLinxCore.interceptors.request.use(async config => {
  config.headers.Accept = 'application/json';

  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export const index = async (searchText, establishmentCode, enterpriseCode) => {
  const params = {
    arg: searchText,
    estaCod: establishmentCode,
    emprCod: enterpriseCode,
  };
  return await urlLinxCore.get('/BuscarMotorista', { params });
};

export const create = async params => {
  return await urlLinxCore.post('/CadastrarMotorista', params);
};

export const fetchUFs = async () => {
  return await urlLinxCore.get('/listar-uf');
};

export const fetchCEP = async cep => {
  return await urlLinxCore.get(`/buscar-cep/${cep}`);
};

export const fetchCitiesByUF = async ufId => {
  const params = {
    ufId: ufId,
  };
  return await urlLinxCore.get('/listar-municipio', { params });
};
