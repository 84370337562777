import styled, { css } from 'styled-components';

const alertStyles = {
  success: {
    background: '#e0f7e5',
    color: '#2e7d32',
  },
  error: {
    background: '#ffebee',
    color: '#c62828',
  },
  warning: {
    background: '#fff3e0',
    color: '#e65100',
  },
  info: {
    background: '#e3f2fd',
    color: '#0d47a1',
  },
};

export const StyledAlert = styled.div`
  padding: 16px;
  border-radius: 4px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  ${({ variant, filled }) => css`
    background-color: ${filled ? alertStyles[variant].color : alertStyles[variant].background};
    color: ${filled ? alertStyles[variant].background : alertStyles[variant].color};
  `}
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`;

export const Message = styled.span`
  flex: 1;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 1.25rem;
  padding-left: 16px;
`;
