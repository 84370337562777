import styled from 'styled-components';

export const StyledTabs = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ gap }) => `${gap || 1}rem`};
  border-bottom: 1px solid #bdbdbd;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
`;

export const StyledTab = styled.button`
  padding: 0.75rem 1rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  border: none;
  background-color: ${({ isActive }) => (isActive ? '#C7B3D6' : '#F5F6F8')};
  color: ${({ isActive }) => (isActive ? '#411E5A' : '#626262')};
  font-weight: ${({ isActive }) => (isActive ? 600 : 500)};
  transition: background-color 0.3s ease, color 0.1s ease, font-weight 0.1s ease;
  box-shadow: ${({ isActive }) => (isActive ? 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' : 'none')};
`;

export const StyledTabWrapper = styled.div`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;
