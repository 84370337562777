import React, { useEffect, useState } from 'react';
import { StyledTable, StyledTableHead, StyledTableRow, StyledTableCell, StyledTablePagination } from './styles';
import { arrayHasContent } from '../../../shared/utils';
import Checkbox from '../Checkbox';
import IconButton from '../IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Spinner';
import Box from '../Box';

const Table = ({ columns, rows, align, onSelect, rowsPerPage, isLoading }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(rows.length / rowsPerPage) || 1;

  const isSelected = row => selectedRows.some(selectedRow => selectedRow.id === row.id);

  const handleRowSelect = row => {
    setSelectedRows(prevSelectedRows => (isSelected(row) ? prevSelectedRows.filter(selectedRow => selectedRow.id !== row.id) : [...prevSelectedRows, row]));
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length === rows.length ? [] : rows);
  };

  const allRowsAreSelected = arrayHasContent(rows) && selectedRows.length === rows.length;

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedRows);
    }
  }, [selectedRows]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderRowCell = (column, row) => {
    if (column.renderCell) {
      return column.renderCell(row);
    }
    return row[column.field] || '';
  };

  const getDisplayedRows = rows => {
    if (rowsPerPage) {
      return rows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    }

    return rows || [];
  };

  return (
    <>
      <StyledTable>
        <StyledTableHead>
          {onSelect && <Checkbox onChange={handleSelectAll} checked={allRowsAreSelected} />}
          {columns.map((column, index) => (
            <StyledTableCell align={align} key={column.field || index} flex={column.flex}>
              {column.label}
            </StyledTableCell>
          ))}
        </StyledTableHead>
        {isLoading ? (
          <Box padding={1}>
            <Spinner color="#552b85" />
          </Box>
        ) : arrayHasContent(getDisplayedRows(rows)) ? (
          getDisplayedRows(rows).map((row, index) => (
            <StyledTableRow key={row.id || index}>
              {onSelect && <Checkbox onChange={() => handleRowSelect(row)} checked={isSelected(row)} />}
              {columns.map(column => (
                <StyledTableCell align={align} key={column.field} flex={column.flex}>
                  {renderRowCell(column, row)}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))
        ) : (
          <StyledTableCell padding={1}>Nenhum dado para exibir na tabela.</StyledTableCell>
        )}
      </StyledTable>

      {rowsPerPage && (
        <StyledTablePagination>
          <span>
            {currentPage} de {totalPages}
          </span>
          <IconButton size={1} disabled={currentPage === 1} onClick={handlePreviousPage} icon={<FontAwesomeIcon icon={faChevronLeft} />} />
          <IconButton size={1} disabled={currentPage === totalPages} onClick={handleNextPage} icon={<FontAwesomeIcon icon={faChevronRight} />} />
        </StyledTablePagination>
      )}
    </>
  );
};

export default Table;
