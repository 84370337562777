import React from 'react';
import { StyledInfoIcon } from './styles';

const InfoIcon = () => {
  return (
    <StyledInfoIcon>
      <b>i</b>
    </StyledInfoIcon>
  );
};

export default InfoIcon;
