import { LoadPanel } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import { alert } from 'devextreme/ui/dialog';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Nav, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { fetchSharedDataInit, selectConsignadoComodatoSharedData, useConsignadoComodatoSharedData } from '../../../redux/slices/consignadoComodato/SharedData.slice';
import { TurnoPedidoService } from '../../../services/turno-pedido/TurnoPedido.service';
import { menus } from '../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../shared/utils/Utils';
import { SessionManager } from '../../../shared/utils/sessionManager';
import { useNovaOs } from './../../../redux/slices/consignadoComodato/novaOsSlice';
import styles from './NovaOS.module.scss';
import './NovaOS.scss';
import { VALUES_OS } from './ValuesOsKey';
import { ImpressaoRecibo } from './components/ImpressaoRecibo/ImpressaoRecibo';
import { ContratoComodato } from './components/contratoComodato/contratoComodato';
import { HeaderForm } from './components/haeader-form/headerForm';
import { ImpressaoPedido } from './components/impressaoPedido/ImpressaoPedido';
import { Faturamento } from './faturamento/faturamento';
import { Pagamentos } from './pagamento/pagamentos';
import { Pedido } from './pedido/pedido';
import { Produtos } from './produto/produtos';
import { Totais } from './totais/totais';
import { OsSubmitService } from './usercases/osSubmit.service';
import { ApiVerificarStatusTrackingService } from '../consignado-comodato-lista/usercases/ApiVerificarStatusTracking.service';
import { ApiCancelarIntegrarTrackingService } from './faturamento/usercases/ApiCancelarIntegrarTracking.service';
import { ApiVerificaHabilitadoVtexTrackingService } from './faturamento/usercases/ApiVerificaHabilitadoVtexTracking.service';

export function NovaOS() {
  const [mounted, setMounted] = useState(true);
  const PATH_MENU = menus.COD_17800;
  const dispatch = useDispatch();
  const { initialize, reset, reload } = useNovaOs();
  const { values, errors, showContrato, showImpressaoPedido, showImpressaoRecibo } = useSelector(state => state.novaOs);
  const { initialized, loading: sharedLoading, error: sharedError } = useSelector(selectConsignadoComodatoSharedData);
  const { updateState } = useConsignadoComodatoSharedData();
  const [searching, setSearching] = useState(true);
  const [activeKey, setActiveKey] = useState('pedido');
  const [messageLoading, setMessageLoading] = useState('Carregando dados...');
  const history = useHistory();
  useEffect(() => {
    setMounted(true);
    if (!mounted) return;
    setSearching(sharedLoading);
    if (sharedError) {
      alert(sharedError.message, 'Falha carregando dados!').then(e => {
        setSearching(false);
        new SessionManager().remove(VALUES_OS);
        history.push('/operacoes/pedidos');
        return;
      });
    }

    dispatch(initialize());
    if (!initialized && !sharedLoading && !sharedError) {
      dispatch(fetchSharedDataInit());
    }
    return () => {};
  }, [dispatch, initialized, sharedLoading, sharedError, history, initialize, mounted]);
  const handleNext = useCallback(() => {
    switch (activeKey) {
      default:
        break;
      case 'pedido':
        setActiveKey('produto');
        break;
      case 'produto':
        setActiveKey('pagamento');
        break;
      case 'pagamento':
        if (values.id >= 0) setActiveKey('faturamento');
        break;
    }
  }, [activeKey, values.id]);
  const handlePreviews = useCallback(() => {
    switch (activeKey) {
      default:
      case 'faturamento':
        setActiveKey('pagamento');
        break;
      case 'pagamento':
        setActiveKey('produto');
        break;
      case 'produto':
        setActiveKey('pedido');
        break;
    }
  }, [activeKey]);

  const habilitadoTracking = new ApiVerificaHabilitadoVtexTrackingService().execute();

  const handlerSubmit = useCallback(async () => {
    const service = new OsSubmitService();
    setMessageLoading('Salvando Pedido...');
    setSearching(true);
    try {
      
     
      if(values.status.nome==="CANCELADO" && habilitadoTracking){
        const pedidosJaIntegrados = await new ApiVerificarStatusTrackingService().execute(values.osId);

        //verifica se pedidosjaintegrados é maior que 0 e se for, pega o ultimo item do array, campo tipoIntegracao e compara se é diferente de "CANCELADA"
        if (pedidosJaIntegrados.length > 0 && pedidosJaIntegrados[pedidosJaIntegrados.length - 1].tipoIntegracao !== "CANCELADA") {
          await new ApiCancelarIntegrarTrackingService().execute({ pedidosSelecionados: [values.osId] });
          }
      }


      var savedOs = await service.execute(values);
      setSearching(false);

      // dispatch(reset());
      dispatch(reload(savedOs));
      setActiveKey('faturamento');
      //history.push('/operacoes/pedidos');
    } catch (error) {
      setSearching(false);
      if (error.message.indexOf('[OS687]') > -1) {
        var ret = await Swal.fire({
          icon: 'info',
          title: 'Atenção!',
          text: 'Não existe um turno aberto para o usuário logado. deseja abrir um turno?',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        });
        if (ret.isConfirmed) {
          try {
            setSearching(true);
            setMessageLoading('Abrindo Turno...');
            var turno = await TurnoPedidoService.getInstance().abrirTurno();
            dispatch(updateState({ name: 'turnoAtual', data: turno }));
            setSearching(false);
          } catch (error) {
            setSearching(false);
            Swal.fire({
              icon: 'error',
              title: 'Falha salvando Pedido',
              text: error.message,
            });
          }
        }
        return;
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Falha salvando Pedido',
          text: error.message,
        });
      }
    }
  }, [values, dispatch, setActiveKey, reload, updateState]);
  function showNextButton() {
    switch (activeKey) {
      default:
      case 'produto':
      case 'pedido':
        return true;
      case 'pagamento':
        return values.id > -1;
      case 'faturamento':
        return false;
    }
  }
  return (
    <LinxPostos noScroll={showContrato || showImpressaoPedido || showImpressaoRecibo} className={styles.container} breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message={messageLoading} showPane={true} />
      {showContrato && <ContratoComodato />}
      {showImpressaoPedido && <ImpressaoPedido />}
      {showImpressaoRecibo && <ImpressaoRecibo />}
      {!showContrato && !showImpressaoPedido && !showImpressaoRecibo && (
        <>
          <HeaderForm />
          <Card>
            <Card.Header bg="primary">
              <Nav variant="pills" onSelect={e => setActiveKey(e)} defaultActiveKey="pedido">
                <Nav.Item>
                  {
                    <Nav.Link active={activeKey === 'pedido'} eventKey="pedido">
                      Dados do Pedido
                    </Nav.Link>
                  }
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link active={activeKey === 'produto'} eventKey="produto">
                    Produtos
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link active={activeKey === 'pagamento'} eventKey="pagamento">
                    Pagamentos
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link active={activeKey === 'faturamento'} eventKey="faturamento" disabled={values.id === -1 || values.needSave}>
                    Faturamento
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      {activeKey === 'pedido' && <Pedido save={handlerSubmit} />}
                      {activeKey === 'produto' && <Produtos setMessageLoadin={setMessageLoading} setSearching={setSearching} />}
                      {activeKey === 'pagamento' && <Pagamentos setMessageLoadin={setMessageLoading} setSearching={setSearching} />}
                      {activeKey === 'faturamento' && <Faturamento />}
                    </Card.Body>
                  </Card>
                </Col>
                <Col style={{ minWidth: '200px', maxWidth: '250px' }}>
                  <Card border="none">
                    <Card.Body>
                      <Totais></Totais>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                onClick={e => {
                  e.preventDefault();
                  dispatch(reset());
                  history.push('/operacoes/pedidos');
                }}
                variant="secondary"
                type="button"
              >
                <i className="dx-icon-clear"></i>
                <span> CANCELAR</span>
              </Button>
              <Button onClick={handlerSubmit} variant="primary" type="button" disabled={!errors.isValid} className="float-right">
                <i className="dx-icon-save"></i>
                <span> SALVAR</span>
              </Button>
              {showNextButton() && (
                <Button onClick={handleNext} variant="primary" type="button" className="float-right mr-1">
                  <span>PRÓXIMO </span>
                  <i className="dx-icon-chevronnext"></i>
                  <i className="dx-icon-chevronnext"></i>
                </Button>
              )}
              {activeKey !== 'pedido' && (
                <Button onClick={handlePreviews} variant="primary" type="button" className="ml-1">
                  <i className="dx-icon-chevronprev"></i>
                  <i className="dx-icon-chevronprev"></i>
                  <span> VOLTAR</span>
                </Button>
              )}
              {
              //descomentar quando local, para se ver os erros de validação da tela 
              //<pre>{JSON.stringify(errors)}</pre>
              }
            </Card.Footer>
          </Card>
        </>
      )}
    </LinxPostos>
  );
}

export default withRouter(NovaOS);
