import React from 'react';
import { StyledInputWrapper, StyledInput, StyledInputBox, StyledAdornment } from './styles';
import { maskInput, formatToCurrency } from '../../../shared/utils/masks';
import InputLabel from '../InputLabel';
import ErrorSpan from '../ErrorSpan';

const specialCharactersRegex = "^[\\p{L}\\p{N}\\s]+$"

const Input = ({
  label,
  info,
  error,
  mask,
  type,
  value,
  name,
  onChange = () => {},
  options = [],
  disabled,
  onKeyDown,
  startAdornment,
  endAdornment,
  maxlength,
  readonly,
  disableSpecialCharacters, 
  ...props
}) => {
  const formatInputValue = value => {
    if (type === 'currency') return formatToCurrency(value);
    if (mask) return maskInput(value, mask);
    return value;
  };

  const handleChange = event => {
    if (disabled || readonly) return;
  
    const { value } = event.target;
  
    let validValue = value;
  
    if (disableSpecialCharacters) {
      const regex = new RegExp(specialCharactersRegex, 'u');
      validValue = value.split('').filter(char => regex.test(char)).join('');
    }
  
    const formattedValue = formatInputValue(validValue);
  
    event.target.value = formattedValue;
    onChange(event);
  };
  

  const handleKeyDown = event => {
    if (onKeyDown) {
      onKeyDown(event);
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const renderAdornment = adornment => {
    if (!adornment) return null;
    return <StyledAdornment>{adornment}</StyledAdornment>;
  };

  return (
    <StyledInputWrapper error={error} disabled={disabled} {...props}>
      <InputLabel>{label}</InputLabel>
      <StyledInputBox error={error} disabled={disabled}>
        {renderAdornment(startAdornment)}
        <StyledInput
          {...props}
          value={formatInputValue(value)}
          name={name}
          onChange={handleChange}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          type={type}
          maxlength={maxlength}
          readonly={readonly}
          readOnly={readonly}
        />
        {renderAdornment(endAdornment)}
      </StyledInputBox>
      <ErrorSpan>{error}</ErrorSpan>
    </StyledInputWrapper>
  );
};

export default Input;
