import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  serie: Yup.string().required('Campo obrigatório'),
  dataEmissao: Yup.date().required('Campo obrigatório'),
  cepOrigem: Yup.string().required('Campo obrigatório'),
  estadoOrigem: Yup.string().required('Campo obrigatório'),
  municipioOrigem: Yup.string().required('Campo obrigatório'),
  cepDestino: Yup.string().required('Campo obrigatório'),
  estadoDestino: Yup.string().required('Campo obrigatório'),
  municipioDestino: Yup.string().required('Campo obrigatório'),
  notasRef: Yup.array()
    .min(1, 'Ao menos uma nota fiscal deve ser informada')
    .required('Campo obrigatório'),
  pesoCarga: Yup.number().required('Campo obrigatório'),
  veiculoId: Yup.string().required('Campo obrigatório'),
  motoristaId: Yup.string().required('Campo obrigatório'),
});
