import { ufOptions } from "../../options";

export const validateCPF = cpf => {
  cpf = cpf.replace(/[^\d]/g, '');

  if (cpf.length !== 11) return false;

  if (/^(\d)\1{10}$/.test(cpf)) return false;

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf[i]) * (10 - i);
  }
  let checkDigit1 = (sum * 10) % 11;
  if (checkDigit1 === 10 || checkDigit1 === 11) checkDigit1 = 0;

  if (checkDigit1 !== parseInt(cpf[9])) return false;

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf[i]) * (11 - i);
  }
  let checkDigit2 = (sum * 10) % 11;
  if (checkDigit2 === 10 || checkDigit2 === 11) checkDigit2 = 0;

  if (checkDigit2 !== parseInt(cpf[10])) return false;

  return true;
};

export const validateCNPJ = cnpj => {
  cnpj = cnpj.replace(/[^\d]/g, '');

  if (cnpj.length !== 14) return false;

  if (/^(\d)\1{13}$/.test(cnpj)) return false;

  let length = cnpj.length - 2;
  let numbers = cnpj.substring(0, length);
  let digits = cnpj.substring(length);
  let sum = 0;
  let pos = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let checkDigit1 = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (checkDigit1 !== parseInt(digits.charAt(0))) return false;

  length = length + 1;
  numbers = cnpj.substring(0, length);
  sum = 0;
  pos = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let checkDigit2 = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (checkDigit2 !== parseInt(digits.charAt(1))) return false;

  return true;
};

export const validateBrazilianPlate = plate => {
  plate = plate.replace(/[^a-zA-Z0-9]/g, '');

  const oldFormat = /^[A-Z]{3}[0-9]{4}$/;

  const newFormat = /^[A-Z]{3}[0-9]{1}[A-Z]{1}[0-9]{2}$/;

  if (oldFormat.test(plate) || newFormat.test(plate)) {
    return true;
  }

  return false;
};

export const validateNumericCode = (code, length) => {
  const numericPattern = /^\d+$/;

  if (length) {
    const lengthPattern = new RegExp(`^\\d{${length}}$`);
    return lengthPattern.test(code);
  }

  return numericPattern.test(code);
};

export const validateUF = (ufId) => {
  return ufOptions.some(({ id }) => String(id) === String(ufId));
}

export const validateCEP = (cep) => {
  cep = cep.replace(/[^\d]/g, '');

  if (cep.length !== 8) return false;

  const cepPattern = /^\d{8}$/;

  return cepPattern.test(cep);
};
