const maskPatterns = {
  '999.999.999-99': val =>
    val
      .replace(/^(\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{3}\.\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{3}\.\d{3}\.\d{3})(\d)/, '$1-$2'),

  '99.999.999/9999-99': val =>
    val
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2}\.\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{2}\.\d{3}\.\d{3})(\d)/, '$1/$2')
      .replace(/^(\d{2}\.\d{3}\.\d{3}\/\d{4})(\d)/, '$1-$2'),

  '99.999-999': val => val.replace(/^(\d{2})(\d)/, '$1.$2').replace(/^(\d{2}\.\d{3})(\d)/, '$1-$2'),

  '(99) 9 9999-9999': val =>
    val
      .replace(/^(\d{2})(\d)/, '($1) $2')
      .replace(/^(\(\d{2}\) \d)(\d)/, '$1 $2')
      .replace(/^(\(\d{2}\) \d \d{4})(\d)/, '$1-$2'),
};

export const unmaskValue = value => {
  if (typeof value !== 'string' && typeof value !== 'number') return '';
  const stringValue = String(value);
  return stringValue.replace(/\D/g, '');
};

export const maskInput = (value, mask) => {
  const unmaskedValue = unmaskValue(value);
  let maskedValue = '';

  if (maskPatterns[mask]) {
    maskedValue = maskPatterns[mask](unmaskedValue);
  } else {
    maskedValue = unmaskedValue;
  }

  return maskedValue;
};

export const formatToCurrency = (value) => {
  let unmaskedValue = value ? value.replace(/\D/g, '') : '';

  if (unmaskedValue === '') {
    return '0,00';
  }
  let intValue = parseInt(unmaskedValue, 10);
  let formattedValue = (intValue / 100).toFixed(2).replace('.', ',');
  return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
