import styled from 'styled-components';

export const StyledOptionsAdornment = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-left: 1px solid #bdbdbd;
  padding: 0.45rem;
  position: relative;
  min-width: 70px;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledOptionsBox = styled.div`
  border: 1px solid rgba(189, 189, 189, 0.5);
  border-radius: 8px;
  position: absolute;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  max-height: 50vh;
  overflow-y: auto;
  top: 100%;
  width: 100%;
`;

export const StyledOption = styled.div`
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(189, 189, 189, 0.5);

  &:hover {
    background-color: aliceblue;
  }
`;
