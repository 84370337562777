import React from 'react';
import { StyledCard } from './styles';

const Card = ({ children, label, ...props }) => {
  return (
    <StyledCard {...props}>
      {Boolean(label) && <strong>{label}</strong>}
      {children}
    </StyledCard>
  );
};

export default Card;
