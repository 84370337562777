import React, { useEffect, useState } from 'react';
import { StyledOption, StyledOptionsAdornment, StyledOptionsBox } from './styles';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayHasContent } from '../../../shared/utils';

const OptionsAdornment = ({ options, value, valueKey = 'value', onSelect = () => {}, disabled }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const findSelectedOption = () => {
    if (!value) {
      setSelectedOption(options[0]);
      return;
    }
  
    const selected = options.find(option => String(option[valueKey]) === String(value));
    if (selected) {
      setSelectedOption(selected);
    }
  };  
  
  const toggleOptions = () => {
    if (disabled) return
    setShowOptions(prev => !prev);
  };

  const handleSelectOption = (event, option) => {
    event.stopPropagation();
    setSelectedOption(option);
    onSelect(option);
    setShowOptions(false);
  };

  useEffect(() => {
    findSelectedOption()
  }, [value]);

  return (
    <StyledOptionsAdornment onClick={toggleOptions}>
      {selectedOption.label || selectedOption}
      <FontAwesomeIcon icon={showOptions ? faChevronUp : faChevronDown} />
      {showOptions && arrayHasContent(options) && (
        <StyledOptionsBox>
          {options.map((option, index) => (
            <StyledOption key={index} onClick={(e) => handleSelectOption(e, option)}>
              {option.label || option}
            </StyledOption>
          ))}
        </StyledOptionsBox>
      )}
    </StyledOptionsAdornment>
  );
};

export default OptionsAdornment;
