import React, { useEffect, useState } from 'react';
import { Box, Card, Input, Tooltip, Table, Chip, IconButton, Alert, Dialog, LoadingOverlay } from '../../../../../components/new';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark, faFloppyDisk, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { getDadosLogin, getDadosUsuario, getNomePagina } from '../../../../../shared/utils/Utils';
import { getDadosEmpresa } from '../../../../configuracoes/empresas/Empresas.service';
import { createMDFeEvent, downloadMDFeXML, fetchMDFeList, fetchMDFeXML } from '../../../../../services/mdfe';
import { formatISODateTime, handlePDFDownload } from '../../../../../shared/utils';
import { useHistory } from 'react-router-dom';
import LinxPostos from '../../../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../../../shared/constants/MenuConstants';
import Tabs from '../Tabs';

const PATH_MENU = menus.COD_10225;

const establishmentCode = getDadosLogin().codigoEstabelecimento || 1;

const renderDataEmissao = row => formatISODateTime(row.dataEmissao);

const renderDataUltimoEvento = row => {
  if (row.dataUltimoEvento) return formatISODateTime(row.dataUltimoEvento);

  if (row.statusMdfe === 0) return 'Arquivo Gerado';

  return '';
};

const renderResponsavelUltimaAcao = row => {
  return row.responsavelUltimaAcao || '-'
}

const getStartOfMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
};

const getToday = () => {
  return new Date().toISOString().split('T')[0];
};

const MDFeTable = () => {
  const [filters, setFilters] = useState({ dataIni: getStartOfMonth(), dataFim: getToday() });
  const [mdfeList, setMdfeList] = useState({ isLoading: true, data: [] });
  const [enterpriseCode, setEnterpriseCode] = useState(null);
  const [alert, setAlert] = useState({ open: false, message: null, variant: null });
  const history = useHistory();
  const [confirmDialog, setConfirmDialog] = useState({ open: false, action: '', row: null });
  const [isLoading, setIsLoading] = useState(false);
  const currentUserName = getDadosUsuario().nome || ""

  const renderStatus = (row) => {
    const { statusMdfe, xmotivo } = row
    const config = {
      0: {
        bgColor: '#0574E9',
        tip: 'Documento salvo, pendente de transmissão.',
        label: 'Gerado',
      },
      1: {
        bgColor: '#09843A',
        tip: 'Salvo e transmitido, com retorno positivo do Sefaz.',
        label: 'Autorizado',
      },
      2: {
        bgColor: '#FFB200',
        tip: 'Salvo e transmitido, posteriormente cancelado.',
        label: 'Cancelado',
      },
      3: {
        bgColor: '#626262',
        tip: 'Salvo, transmitido e encerrado.',
        label: 'Encerrado',
      },
      4: {
        bgColor: '#C0000C',
        tip: xmotivo || 'Falha de schema do arquivo ou rejeição por informação incorreta.',
        label: 'Rejeitado',
      },
    };

    const { tip, label, bgColor } = config[statusMdfe];

    return (
      <Tooltip text={tip || ''}>
        <Chip label={label} bgColor={bgColor || ''} />
      </Tooltip>
    );
  };

  const navigateToMDFe = row => {
    history.push('/nf-e/MDFe/Geracao', row);
  };

  const renderActions = row => {
    const { statusMdfe } = row
    const isSendDisabled = [1, 2, 3].includes(parseInt(statusMdfe))
    const isDownloadDisabled = [0, 4].includes(parseInt(statusMdfe))
    const isCloseDisabled = parseInt(statusMdfe) !== 1
    const isCancelDisabled = parseInt(statusMdfe) !== 1
    return (
      <>
        <IconButton
          size={1.25}
          tip="Ver"
          onClick={() => navigateToMDFe(row)}
          icon={<FontAwesomeIcon icon={faEye} />}
        />
        <IconButton
          size={1.25}
          tip="Enviar"
          onClick={() => handleIconClick('send', row)}
          icon={<FontAwesomeIcon icon={faPaperPlane} />}
          disabled={isSendDisabled}
        />
        <IconButton
          size={1.25}
          tip="Download do Arquivo"
          onClick={() => handleDownloadXML(row)}
          icon={<FontAwesomeIcon icon={faDownload} />}
          disabled={isDownloadDisabled}
        />
        <IconButton
          size={1.25}
          tip="Encerrar"
          onClick={() => handleIconClick('close', row)}
          icon={<FontAwesomeIcon icon={faCircleCheck} />}
          disabled={isCloseDisabled}
        />
        <IconButton
          size={1.25}
          tip="Cancelar"
          onClick={() => handleIconClick('cancel', row)}
          icon={<FontAwesomeIcon icon={faCircleXmark} />}
          disabled={isCancelDisabled}
        />
      </>
    );
  };

  const handleIconClick = (action, row) => {
    setConfirmDialog({ open: true, action: action, row: row });
  };

  const handleConfirmDialog = async () => {
    const { action, row } = confirmDialog;
    const eventCodeConfig = {
      cancel: 110111,
      close: 110112,
    };
    switch (action) {
      case 'send':
        await generateMdfeXML(row);
        break;
      case 'close':
        await generateMdfeEvent(row, eventCodeConfig[action]);
        break;
      case 'cancel':
        await generateMdfeEvent(row, eventCodeConfig[action]);
        break;
      default:
        break;
    }
    resetConfirmDialog();
  };

  const columns = [
    { field: 'numeroMdfe', label: 'Nº MDFe', flex: 1 },
    { field: 'serie', label: 'Série', flex: 1 },
    { field: 'dataEmissao', label: 'Data de Emissão', flex: 2, renderCell: renderDataEmissao },
    { field: 'statusMdfe', label: 'Status', flex: 1, renderCell: renderStatus },
    { field: 'responsavelUltimaAcao', label: 'Usuário', flex: 1, renderCell: renderResponsavelUltimaAcao },
    { field: 'dataUltimoEvento', label: 'Data Último Evento', flex: 2, renderCell: renderDataUltimoEvento },
    { field: 'actions', flex: 1, renderCell: renderActions },
  ];

  const handleFiltersChange = ({ target }) => {
    const { name, value } = target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const getEntrepriseInfo = async () => {
    try {
      const { data } = await getDadosEmpresa();
      setEnterpriseCode(data.data.id);
    } catch (error) {
      console.log(error);
    }
  };

  const getMdfeList = async () => {
    setAlert({ open: true, message: "Carregando MDFe's..." });
    setMdfeList({ isLoading: true, data: [] });
    try {
      const params = {
        dataIni: filters.dataIni,
        dataFim: filters.dataFim,
        estaCod: establishmentCode,
        emprCod: enterpriseCode,
      };
      const { data } = await fetchMDFeList(params);
      if (!data.length) {
        setAlert({ open: true, message: 'Nenhuma MDFe encontrada para o período.', variant: 'warning' });
        return;
      }
      closeAlert();
      setMdfeList(prev => ({ ...prev, data: data }));
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: "Erro ao buscar MDFe's", variant: 'error' });
    } finally {
      setMdfeList(prev => ({ ...prev, isLoading: false }));
    }
  };

  const generateMdfeXML = async row => {
    setIsLoading(true);
    setAlert({ open: true, message: 'Gerando arquivo XML...' });
    try {
      const params = {
        estaCod: row.estaCod,
        emprCod: row.emprCod,
        numeroMDFE: row.numeroMdfe,
        serieMDFE: row.serie,
        responsavelUltimaAcao: currentUserName
      };
      await fetchMDFeXML(params);
      setAlert({ open: true, message: 'Arquivo XML gerado com sucesso', variant: 'success' });
      getMdfeList()
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao gerar o arquivo XML', variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const generateMdfeEvent = async (row, eventCode) => {
    setIsLoading(true);
    setAlert({ open: true, message: 'Gerando evento...' });
    try {
      const params = {
        estaCod: row.estaCod,
        emprCod: row.emprCod,
        numeroMDFE: row.numeroMdfe,
        serieMDFE: row.serie,
        responsavelUltimaAcao: currentUserName,
        tpEvento: eventCode,
      };
      await createMDFeEvent(params);
      setAlert({ open: true, message: 'Evento gerado com sucesso', variant: 'success' });
      getMdfeList();
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao gerar evento', variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadXML = async (row) => {
    setIsLoading(true);
    setAlert({ open: true, message: 'Buscando arquivo XML...' });
    try {
      const params = {
        estaCod: row.estaCod,
        emprCod: row.emprCod,
        numeroMDFE: row.numeroMdfe,
        serieMDFE: row.serie,
      };
      const { data } = await downloadMDFeXML(params);
      if (!data) {
        setAlert({ open: true, message: 'Arquivo indisponível', variant: 'warning' });
        return;
      }
      handlePDFDownload(data)
      setAlert({ open: true, message: 'Arquivo baixado com sucesso', variant: 'success' });
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao baixar arquivo', variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    enterpriseCode ? getMdfeList() : getEntrepriseInfo();
  }, [enterpriseCode]);

  const closeAlert = () => {
    setAlert({ open: false, message: null, variant: null });
  };

  const resetConfirmDialog = () => {
    setConfirmDialog({ open: false, action: '', row: null });
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
      <LoadingOverlay isLoading={isLoading}>
        <Card marginTop={2}>
          <Box justifyContent="space-between" alignItems="center">
            <h1>MDFe</h1>
          </Box>
          <Tabs />
          <Card>
            <Box gap={1}>
              <Input label="Período (Início)" name="dataIni" value={filters.dataIni} onChange={handleFiltersChange} type="date" flex={1} />
              <Input label="Período (Fim)" type="date" name="dataFim" value={filters.dataFim} onChange={handleFiltersChange} flex={1} />
              <IconButton size={1.25} icon={<FontAwesomeIcon icon={faSearch} />} style={{ alignSelf: 'flex-end' }} onClick={getMdfeList} />
            </Box>
            <Table columns={columns} isLoading={mdfeList.isLoading} rows={mdfeList.data} rowsPerPage={25} />
          </Card>
        </Card>
        <Dialog
          isOpen={confirmDialog.open}
          setIsOpen={resetConfirmDialog}
          title="Deseja confirmar a operação?"
          buttons={[
            { label: 'Não', onClick: resetConfirmDialog },
            { label: 'Sim', onClick: handleConfirmDialog },
          ]}
        />
      </LoadingOverlay>
      <Alert isOpen={alert.open} message={alert.message} variant={alert.variant} onClose={closeAlert} />
    </LinxPostos>
  );
};

export default MDFeTable;
