import { AxiosErrorService } from '../../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../../services/core/urlLinxCore.service';

export class ApiVerificaHabilitadoVtexTrackingService {
  urlLinxCore = null;

  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute() {
    try {
      // Faz a requisição para verificar se está habilitado
      const resp = await this.urlLinxCore.get('/IntegraVtexTracking/VerificaHabilitadoVtexTracking/');
      return resp.data;
    } catch (er) {
      await new AxiosErrorService().execute(er);
      return false;
    }
  }
}
