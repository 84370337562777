import React, { useEffect, useRef, useState } from 'react';
import { Alert, Autocomplete, Box, Button, Card, Chip, Dialog, Grid, IconButton, Input, LoadingOverlay, OptionsAdornment, Select } from '../../../../../components/new';
import { Formik, Form } from 'formik';
import { validationSchema } from './validationSchema';
import { bodyworkOptions, ufOptions, wheeledOptions } from '../../../../../shared/options';
import { formInitialData } from './formInitialData';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { maskInput, unmaskValue } from '../../../../../shared/utils/masks';
import { fetchCEP, fetchCitiesByUF } from '../../../../../services/location';
import { getDadosEmpresa } from '../../../../configuracoes/empresas/Empresas.service';
import { arrayHasContent, debounce, removeEmptyValues, sumByKey } from '../../../../../shared/utils';
import { index as fetchVehicles } from '../../../../../services/vehicles/index.service';
import { index as fetchDrivers } from '../../../../../services/drivers/index.service';
import { getDadosLogin, getDadosUsuario, getNomePagina } from '../../../../../shared/utils/Utils';
import { createMDFe, fetchFiscalNotes, fetchMDFe, fetchMDFeXML } from '../../../../../services/mdfe';
import InputLabel from '../../../../../components/new/InputLabel';
import { useLocation } from 'react-router-dom';
import { menus } from '../../../../../shared/constants/MenuConstants';
import LinxPostos from '../../../../../components/core/linxPostos/LinxPostos';
import Tabs from '../Tabs';

const PATH_MENU = menus.COD_10220;

const establishmentCode = getDadosLogin().codigoEstabelecimento || 1;

const MDFeForm = () => {
  const [alert, setAlert] = useState({ open: false, message: null, variant: null });
  const [isLoadingOriginCep, setIsLoadingOriginCep] = useState(false);
  const [isLoadingDestinationCep, setIsLoadingDestinationCep] = useState(false);
  const [enterpriseCode, setEnterpriseCode] = useState(null);
  const [vehicleOptions, setVehicleOptions] = useState({ isLoading: false, data: [] });
  const [driverOptions, setDriverOptions] = useState({ isLoading: false, data: [] });
  const [isLoadingXml, setIsLoadingXml] = useState(false);
  const [sefazDialog, setSefazDialog] = useState({ open: false, mdfe: null });
  const [originCities, setOriginCities] = useState([]);
  const [destinationCities, setDestinationCities] = useState([]);
  const { state } = useLocation();
  const formikRef = useRef(null);
  const [isLoadingMdfe, setIsLoadingMdfe] = useState(false);
  const currentUserName = getDadosUsuario().nome || '';
  const [isEditing, setIsEditing] = useState(false);

  const getEntrepriseInfo = async () => {
    try {
      const { data } = await getDadosEmpresa();
      setEnterpriseCode(data.data.id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEntrepriseInfo();
  }, []);

  const getCEPInfo = async (cep, key, setFieldValue) => {
    if (key === 'cepOrigem') {
      setIsLoadingOriginCep(true);
    } else if (key === 'cepDestino') {
      setIsLoadingDestinationCep(true);
    }
    try {
      const { data } = await fetchCEP(cep);
      if (!data.item.cep) {
        setAlert({ open: true, message: 'Sem dados para o CEP informado.', variant: 'warning' });
        return;
      }

      if (key === 'cepOrigem') {
        if (data.item.uf) {
          setFieldValue('ufOrigem', data.item.uf.descricao);
          setFieldValue('estadoOrigem', data.item.uf.id);
        }
        if (data.item.municipio) {
          setFieldValue('cidadeOrigem', data.item.municipio.descricao);
          setFieldValue('municipioOrigem', data.item.municipio.id);
        } else {
          getUFCities(data.item.uf.id, 'origin');
        }
      } else if (key === 'cepDestino') {
        if (data.item.uf) {
          setFieldValue('ufDestino', data.item.uf.descricao);
          setFieldValue('estadoDestino', data.item.uf.id);
        }
        if (data.item.municipio) {
          setFieldValue('cidadeDestino', data.item.municipio.descricao);
          setFieldValue('municipioDestino', data.item.municipio.id);
        } else {
          getUFCities(data.item.uf.id, 'destination');
        }
      }
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao buscar dados do CEP.', variant: 'error' });
    } finally {
      if (key === 'cepOrigem') {
        setIsLoadingOriginCep(false);
      } else if (key === 'cepDestino') {
        setIsLoadingDestinationCep(false);
      }
    }
  };

  const getUFCities = async (ufId, key) => {
    try {
      const { data } = await fetchCitiesByUF(ufId);
      if (key === 'origin') {
        setOriginCities(data.itens);
      } else if (key === 'destination') {
        setDestinationCities(data.itens);
      }
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao buscar municípios da UF.', variant: 'error' });
    }
  };

  const handleCityChange = (event, kind, setFieldValue) => {
    const upperCasedValue = event.target.value.toUpperCase();
  
    const config = {
      origin: { field: 'cidadeOrigem', options: originCities },
      destination: { field: 'cidadeDestino', options: destinationCities },
    };
  
    const { field, options } = config[kind] || {};
  
    if (field) {
      setFieldValue(field, upperCasedValue);
  
      const matchingOption = (options || []).find(
        ({ descricao }) => descricao === upperCasedValue
      );
  
      if (matchingOption) {
        handleSelectCity(matchingOption, kind, setFieldValue);
      }
    }
  };
  

  const handleSelectCity = (selected, kind, setFieldValue) => {
    if (kind === 'origin') {
      setFieldValue('cidadeOrigem', selected.descricao);
      setFieldValue('municipioOrigem', selected.id);
    } else if (kind === 'destination') {
      setFieldValue('cidadeDestino', selected.descricao);
      setFieldValue('municipioDestino', selected.id);
    }
  }

  const handleCEPChange = ({ target }, setFieldValue) => {
    const { name, value } = target;
    setFieldValue(name, value);

    const unmaskedCEP = unmaskValue(value);
    if (unmaskedCEP.length === 8) {
      getCEPInfo(unmaskedCEP, name, setFieldValue);
      return;
    }

    if (name === 'cepOrigem') {
      setIsLoadingOriginCep(false);
      setFieldValue('ufOrigem', '');
      setFieldValue('cidadeOrigem', '');
      setFieldValue('estadoOrigem', '');
      setFieldValue('municipioOrigem', '');
    } else if (name === 'cepDestino') {
      setIsLoadingDestinationCep(false);
      setFieldValue('ufDestino', '');
      setFieldValue('cidadeDestino', '');
      setFieldValue('estadoDestino', '');
      setFieldValue('municipioDestino', '');
    }
  };

  const closeAlert = () => {
    setAlert({ open: false, message: null, variant: null });
  };

  const generateMDFeXML = async (mdfe = {}) => {
    setIsLoadingXml(true);
    setAlert({ open: true, message: 'Gerando arquivo XML...' });
    try {
      const params = {
        estaCod: mdfe.estaCod,
        emprCod: mdfe.emprCod,
        numeroMDFE: mdfe.numeroMdfe,
        serieMDFE: mdfe.serie,
        responsavelUltimaAcao: currentUserName,
      };
      await fetchMDFeXML(params);
      setAlert({ open: true, message: 'Arquivo XML gerado com sucesso', variant: 'success' });
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao gerar XML', variant: 'error' });
    } finally {
      setIsLoadingXml(false);
    }
  };

  const sanitizeFormValues = values => {
    const sanitizedValues = removeEmptyValues(values);
    sanitizedValues.qtdNfeVinculadas = values.notasRef.length;
    sanitizedValues.valorCarga = sumByKey(values.notasRef);
    sanitizedValues.cepOrigem = unmaskValue(sanitizedValues.cepOrigem);
    sanitizedValues.cepDestino = unmaskValue(sanitizedValues.cepDestino);
    sanitizedValues.emprCod = enterpriseCode;
    sanitizedValues.unMedida = String(sanitizedValues.unMedida);
    sanitizedValues.responsavelUltimaAcao = currentUserName;
    if (!sanitizedValues.chave) sanitizedValues.chave = '';
    delete sanitizedValues.numeroNota;
    delete sanitizedValues.serieNota;
    delete sanitizedValues.dataIni;
    delete sanitizedValues.dataFim;
    delete sanitizedValues.ufOrigem;
    delete sanitizedValues.ufDestino;
    delete sanitizedValues.cidadeOrigem;
    delete sanitizedValues.cidadeDestino;
    delete sanitizedValues.cpfMotorista;
    delete sanitizedValues.nomeMotorista;
    delete sanitizedValues.placaVeiculo;
    delete sanitizedValues.descricao;
    delete sanitizedValues.renavam;
    delete sanitizedValues.tara;
    delete sanitizedValues.tipoCarroceria;
    delete sanitizedValues.tipoRodado;
    delete sanitizedValues.ufVeiculo;
    delete sanitizedValues.placaReboque;
    delete sanitizedValues.capacidade;
    return sanitizedValues;
  };

  const handleSubmit = async (values, { validateForm }) => {
    const errors = await validateForm(values);
    const isFormValid = Object.keys(errors).length === 0;
    if (isFormValid) {
      const sanitizedValues = sanitizeFormValues(values);
      setSefazDialog({ open: true, mdfe: sanitizedValues });
    }
  };

  const generateMDFe = async callback => {
    setSefazDialog(prev => ({ ...prev, open: false }));
    setIsLoadingMdfe(true);
    const isEditing = Boolean(sefazDialog.mdfe.mdfeId)
    try {
      const { data } = await createMDFe(sefazDialog.mdfe);
      setAlert({ open: true, message: `MDFe ${isEditing ? "editado" : "criado"} com sucesso`, variant: 'success' });
      if (callback) {
        callback(data[0]);
      }
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: `Erro ao ${isEditing ? "editar" : "criar"} MDFe.`, variant: 'error' });
    } finally {
      setIsLoadingMdfe(false);
      setSefazDialog(prev => ({ ...prev, mdfe: null }));
    }
  };

  const handlePlateChange = ({ target }, setFieldValue) => {
    setFieldValue('placaVeiculo', target.value.toUpperCase());
    getVehicles(target.value.toUpperCase(), setFieldValue);
    setFieldValue('veiculoId', '');
    setFieldValue('descricao', '');
    setFieldValue('renavam', '');
    setFieldValue('tara', '');
    setFieldValue('tipoCarroceria', '');
    setFieldValue('tipoRodado', '');
    setFieldValue('capacidade', '');
    setFieldValue('ufVeiculo', '');
    setFieldValue('placaReboque', '');
  };

  const getVehicles = debounce(async searchPlate => {
    setVehicleOptions({ isLoading: true, data: [] });
    try {
      const { data } = await fetchVehicles(searchPlate, establishmentCode, enterpriseCode);
      setVehicleOptions(prev => ({ ...prev, data: data }));
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao buscar dados do veículo.', variant: 'error' });
    } finally {
      setVehicleOptions(prev => ({ ...prev, isLoading: false }));
    }
  }, 500);

  const handleSelectVehicle = (selected, setFieldValue) => {
    const { veiculoId, descricao, renavam, tara, tipoCarroceria, tipoRodado, capacidade, ufVeiculo, placaReboque, placaVeiculo } = selected;
    setFieldValue('placaVeiculo', placaVeiculo);
    setFieldValue('veiculoId', veiculoId);
    setFieldValue('descricao', descricao);
    setFieldValue('renavam', renavam);
    setFieldValue('tara', tara);
    setFieldValue('tipoCarroceria', tipoCarroceria);
    setFieldValue('tipoRodado', tipoRodado);
    setFieldValue('capacidade', capacidade);
    setFieldValue('ufVeiculo', ufVeiculo);
    if (placaReboque) setFieldValue('placaReboque', placaReboque);
  };

  const handleVehicleOnKeyDown = (event, setFieldValue, values) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.target.value.length === 7) {
        const selected = vehicleOptions.data.find(({ placaVeiculo }) => placaVeiculo === event.target.value);
        if (selected) {
          handleSelectVehicle(selected, setFieldValue);
        } else {
          setAlert({ open: true, message: 'Nenhum registro encontrado para esta placa.', variant: 'warning' });
        }
      }
    } else if (event.key === 'Backspace' && values.veiculoId) {
      event.preventDefault();
      setFieldValue('placaVeiculo', '');
      setFieldValue('veiculoId', '');
      setFieldValue('descricao', '');
      setFieldValue('renavam', '');
      setFieldValue('tara', '');
      setFieldValue('tipoCarroceria', '');
      setFieldValue('tipoRodado', '');
      setFieldValue('capacidade', '');
      setFieldValue('ufVeiculo', '');
      setFieldValue('placaReboque', '');
    }
  };

  const getDrivers = debounce(async search => {
    setDriverOptions({ isLoading: true, data: [] });
    try {
      const { data } = await fetchDrivers(search, establishmentCode, enterpriseCode);
      setDriverOptions(prev => ({ ...prev, data: data }));
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao buscar dados de motoristas.', variant: 'error' });
    } finally {
      setDriverOptions(prev => ({ ...prev, isLoading: false }));
    }
  }, 500);

  const handleCpfCnpjChange = ({ target }, setFieldValue) => {
    const { name, value } = target;
    setFieldValue(name, value);
    const unmaskedValue = unmaskValue(value);
    getDrivers(unmaskedValue, setFieldValue);
  };

  const handleSelectDriver = (selected, setFieldValue) => {
    const { motoristaId, cpf, nome } = selected;
    setFieldValue('motoristaId', motoristaId);
    setFieldValue('cpfMotorista', cpf);
    setFieldValue('nomeMotorista', nome);
  };

  const handleDriverOnKeyDown = (event, setFieldValue, values) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const unmaskedCpf = unmaskValue(event.target.value);
      if (unmaskedCpf.length === 11) {
        const selected = driverOptions.data.find(({ cpf }) => String(cpf) === String(unmaskedCpf));
        if (selected) {
          handleSelectDriver(selected, setFieldValue);
        } else {
          setAlert({ open: true, message: 'Nenhum registro encontrado para este CPF.', variant: 'warning' });
        }
      }
    } else if (event.key === 'Backspace' && values.motoristaId) {
      event.preventDefault();
      setFieldValue('motoristaId', '');
      setFieldValue('cpfMotorista', '');
      setFieldValue('nomeMotorista', '');
    }
  };

  const getFiscalNotes = async (e, values, setFieldValue) => {
    e.preventDefault();
    setIsLoadingMdfe(true);
    try {
      let message = '';

      if (!values.numeroNota) {
        message = 'Insira o número da nota para pesquisar';
      } else if (!values.serieNota) {
        message = 'Insira o número da série para pesquisar';
      } else if (!values.dataIni) {
        message = 'Insira a data inicial para pesquisar';
      } else if (!values.dataFim) {
        message = 'Insira a data final para pesquisar';
      }

      if (message) {
        setAlert({
          open: true,
          message,
          variant: 'warning',
        });
        return;
      }

      const params = {
        estaCod: values.estaCod,
        emprCod: establishmentCode,
        numeroNota: values.numeroNota,
        serieNota: values.serieNota,
        dataIni: values.dataIni,
        dataFim: values.dataFim,
      };

      const updatedParams = removeEmptyValues(params);
      const { data } = await fetchFiscalNotes(updatedParams);

      if (!arrayHasContent(data)) {
        setAlert({
          open: true,
          message: 'Atenção! Documento inválido para MDF-e.',
          variant: 'warning',
        });
        return;
      }

      const existingKeys = values.notasRef.map(item => item.chave);
      if (existingKeys.includes(data[0].chave)) {
        setAlert({
          open: true,
          message: 'Atenção! Documento já adicionado.',
          variant: 'warning',
        });
      } else {
        setAlert({
          open: true,
          message: 'Nota adicionada com sucesso',
          variant: 'success',
        });
        setFieldValue('notasRef', [...values.notasRef, data[0]]);
        setFieldValue('numeroNota', '');
        setFieldValue('serieNota', '');
      }
    } catch (error) {
      console.log(error);
      setAlert({
        open: true,
        message: 'Erro ao buscar dados de notas fiscais.',
        variant: 'error',
      });
    } finally {
      setIsLoadingMdfe(false);
    }
  };

  const handleRemoveFiscalNote = (e, chave, values, setFieldValue) => {
    e.preventDefault();
    const updatedNotasRef = values.notasRef.filter(nota => nota.chave !== chave);
    setFieldValue('notasRef', updatedNotasRef);
  };

  const getMDFe = async mdfe => {
    setIsLoadingMdfe(true);
    try {
      const params = {
        estaCod: mdfe.estaCod,
        emprCod: mdfe.emprCod,
        numeroMDFE: mdfe.numeroMdfe,
        serieMDFE: mdfe.serie,
      };
      const { data } = await fetchMDFe(params);
      if (data[0]) {
        handleMDFeData(data[0]);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingMdfe(false);
    }
  };

  const getDriverById = async (id, setFieldValue, estaCod, emprCod) => {
    try {
      const { data } = await fetchDrivers(id, estaCod, emprCod);
      const { motoristaId, cpf, nome } = data[0];
      setFieldValue('motoristaId', motoristaId);
      setFieldValue('cpfMotorista', cpf);
      setFieldValue('nomeMotorista', nome);
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao buscar dados do motorista.', variant: 'error' });
    }
  };

  const getVehicleById = async (id, setFieldValue, estaCod, emprCod) => {
    try {
      const { data } = await fetchVehicles(null, estaCod, emprCod, id);
      const { veiculoId, descricao, renavam, tara, tipoCarroceria, tipoRodado, capacidade, ufVeiculo, placaReboque, placaVeiculo } = data[0];
      setFieldValue('placaVeiculo', placaVeiculo);
      setFieldValue('veiculoId', veiculoId);
      setFieldValue('descricao', descricao);
      setFieldValue('renavam', renavam);
      setFieldValue('tara', tara);
      setFieldValue('tipoCarroceria', tipoCarroceria);
      setFieldValue('tipoRodado', tipoRodado);
      setFieldValue('capacidade', capacidade);
      setFieldValue('ufVeiculo', ufVeiculo);
      if (placaReboque) setFieldValue('placaReboque', placaReboque);
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao buscar dados do veículo.', variant: 'error' });
    }
  };

  const handleMDFeData = async (mdfe = {}) => {
    const setFieldValue = formikRef.current.setFieldValue;
    const {
      numeroMdfe,
      serie,
      dataEmissao,
      chave,
      cepOrigem,
      cepDestino,
      notasRef,
      unMedida,
      pesoCarga,
      motoristaId,
      veiculoId,
      statusMdfe,
      infAdc,
      emprCod,
      estaCod,
      municipioDestino,
      municipioOrigem,
      mdfeId
    } = mdfe;
    setFieldValue('mdfeId', mdfeId);
    setFieldValue('numeroMdfe', numeroMdfe);
    setFieldValue('serie', serie);
    setFieldValue('statusMdfe', statusMdfe);
    let mdfeDate = new Date(dataEmissao);
    mdfeDate = mdfeDate.toISOString().split('T')[0];
    setFieldValue('dataEmissao', mdfeDate);
    setFieldValue('notasRef', notasRef);
    setFieldValue('unMedida', unMedida);
    setFieldValue('pesoCarga', pesoCarga);
    setFieldValue('chave', chave);
    setFieldValue('cepOrigem', cepOrigem);
    setFieldValue('cepDestino', cepDestino);
    setFieldValue('infAdc', infAdc);
    setFieldValue('municipioOrigem', municipioOrigem);
    setFieldValue('municipioDestino', municipioDestino);
    await getCEPInfo(cepOrigem, 'cepOrigem', setFieldValue);
    await getCEPInfo(cepDestino, 'cepDestino', setFieldValue);
    await getDriverById(motoristaId, setFieldValue, estaCod, emprCod);
    await getVehicleById(veiculoId, setFieldValue, estaCod, emprCod);
    setIsLoadingMdfe(false);
  };

  useEffect(() => {
    if (state) {
      getMDFe(state);
    }
  }, [state]);

  const renderStatus = status => {
    const config = {
      0: {
        bgColor: '#0574E9',
        tip: 'Documento salvo, pendente de transmissão.',
        label: 'Gerado',
      },
      1: {
        bgColor: '#09843A',
        tip: 'Salvo e transmitido, com retorno positivo do Sefaz.',
        label: 'Autorizado',
      },
      2: {
        bgColor: '#FFB200',
        tip: 'Salvo e transmitido, posteriormente cancelado.',
        label: 'Cancelado',
      },
      3: {
        bgColor: '#626262',
        tip: 'Salvo, transmitido e encerrado.',
        label: 'Encerrado',
      },
      4: {
        bgColor: '#C0000C',
        tip: 'Falha de schema do arquivo ou rejeição por informação incorreta.',
        label: 'Rejeitado',
      },
    };

    if (!config[status]) return null;

    const { label, bgColor } = config[status];

    return <Chip label={label} bgColor={bgColor || ''} />;
  };

  const handleGenerateXML = e => {
    e.preventDefault();
    generateMDFeXML(state);
  };

  const formatDriverOption = option => `${maskInput(option.cpf, '999.999.999-99')} - ${option.nome}`;

  const isEditable = (status) => {
    if (typeof status !== "number") return false;
    const statusNumber = parseInt(status)
    const editableStatus = [0, 4]
    return editableStatus.includes(statusNumber)
  }

  const allowEditing = (e) => {
    e.preventDefault()
    setIsEditing(true)
  }

  const formatTotalValue = (totalValue) => {
    const total = sumByKey(totalValue)
    return parseFloat(total).toFixed(2).replace('.', ',')
  }

  const renderButtons = (values) => {
    const { statusMdfe, numeroMdfe } = values;
    
    if (!numeroMdfe) {
      return <Button type="submit">Gerar MDFe</Button>;
    }
  
    if (isEditable(statusMdfe)) {
      return (
        <>
          <Button variant="outlined" onClick={allowEditing}>Habilitar Edição</Button>
          <Button type="submit">Gerar XML</Button>
        </>
      );
    }
  
    return <Button onClick={handleGenerateXML}>Gerar XML</Button>;
  }

  const isReadOnly = (values) => Boolean(values.numeroMdfe && !isEditing)

  return (
    <>
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <LoadingOverlay isLoading={isLoadingMdfe || isLoadingXml}>
          <Formik innerRef={formikRef} initialValues={formInitialData} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, isSubmitting, setFieldValue }) => (
              <Form id="mdfeForm">
                <Card marginTop={2}>
                  <Box justifyContent="space-between" alignItems="center">
                    <h1>MDFe</h1>
                    <Box gap={1}>{renderButtons(values)}</Box>
                  </Box>
                  <Tabs />
                  <Card gap={1.5}>
                    <Box alignItems="center" justifyContent="space-between">
                      <h2>Dados do MDFe</h2>
                      {renderStatus(values.statusMdfe)}
                    </Box>
                    <Box gap={1}>
                      <Input
                        id="numeroMdfe"
                        label="Número do MDFe"
                        placeholder="Número do MDFe"
                        name="numeroMdfe"
                        value={values.numeroMdfe}
                        onChange={handleChange}
                        readonly={values.numeroMdfe}
                        disabled={!values.numeroMdfe}
                      />
                      <Input
                        id="serie"
                        label="Série"
                        placeholder="Insira a série"
                        name="serie"
                        value={values.serie}
                        onChange={handleChange}
                        error={touched.serie && errors.serie}
                        readonly={isReadOnly(values)}
                      />
                      <Input
                        id="dataEmissao"
                        type="date"
                        label="Data de emissão"
                        placeholder="00/00/0000"
                        name="dataEmissao"
                        value={values.dataEmissao}
                        onChange={handleChange}
                        error={touched.dataEmissao && errors.dataEmissao}
                        readonly={isReadOnly(values)}
                        max="9999-12-31"
                      />
                    </Box>
                    <Input
                      id="chave"
                      label="Chave de Acesso"
                      placeholder="Chave de acesso do MDFe"
                      name="chave"
                      value={values.chave}
                      onChange={handleChange}
                      readonly={values.chave}
                      disabled={!values.chave}
                    />
                    <Card label="Dados da Operação">
                      <Grid columns={3}>
                        <Autocomplete
                          id="cepOrigem"
                          label="CEP origem da carga"
                          placeholder="Insira o CEP de origem da carga"
                          name="cepOrigem"
                          mask="99.999-999"
                          value={values.cepOrigem}
                          onChange={e => handleCEPChange(e, setFieldValue)}
                          error={touched.cepOrigem && errors.cepOrigem}
                          readonly={isReadOnly(values)}
                          isLoading={isLoadingOriginCep}
                          maxlength={10}
                          nameKey="cep"
                          options={[]}
                          hideOptions
                        />
                        <Input
                          id="ufOrigem"
                          label="UF origem da carga"
                          placeholder="Insira a UF de origem da carga"
                          name="ufOrigem"
                          value={values.ufOrigem}
                          onChange={handleChange}
                          readonly
                          disabled={!values.ufOrigem}
                        />
                        {arrayHasContent(originCities) ? (
                          <Autocomplete
                            id="cidadeOrigem"
                            options={originCities}
                            label="Cidade de Origem da Carga"
                            placeholder="Insira a cidade de origem da carga"
                            name="cidadeOrigem"
                            nameKey="descricao"
                            value={values.numeroMdfe ? values.municipioOrigem : values.cidadeOrigem}
                            onChange={e => handleCityChange(e, 'origin', setFieldValue)}
                            onSelect={selected => handleSelectCity(selected, 'origin', setFieldValue)}
                            readonly={isReadOnly(values)}
                            hideOptions={isReadOnly(values)}
                          />
                        ) : (
                          <Input
                            id="cidadeOrigem"
                            label="Cidade de Origem da Carga"
                            placeholder="Insira a cidade de origem da carga"
                            name="cidadeOrigem"
                            value={values.cidadeOrigem}
                            onChange={handleChange}
                            readonly
                            disabled={!values.cidadeOrigem}
                          />
                        )}
                        <Autocomplete
                          id="cepDestino"
                          label="CEP destino da carga"
                          placeholder="Insira o CEP de destino da carga"
                          name="cepDestino"
                          mask="99.999-999"
                          value={values.cepDestino}
                          onChange={e => handleCEPChange(e, setFieldValue)}
                          error={touched.cepDestino && errors.cepDestino}
                          readonly={isReadOnly(values)}
                          isLoading={isLoadingDestinationCep}
                          maxlength={10}
                          nameKey="cep"
                          options={[]}
                          hideOptions
                        />
                        <Input
                          id="ufDestino"
                          label="UF destino da carga"
                          placeholder="Insira a UF de destino da carga"
                          name="ufDestino"
                          value={values.ufDestino}
                          onChange={handleChange}
                          readonly
                          disabled={!values.ufDestino}
                        />
                        {arrayHasContent(destinationCities) ? (
                          <Autocomplete
                            id="cidadeDestino"
                            options={destinationCities}
                            label="Cidade de Destino da Carga"
                            placeholder="Insira a cidade de destino da carga"
                            name="cidadeDestino"
                            nameKey="descricao"
                            value={values.numeroMdfe ? values.municipioDestino : values.cidadeDestino}
                            readonly={isReadOnly(values)}
                            onChange={e => handleCityChange(e, 'destination', setFieldValue)}
                            onSelect={selected => handleSelectCity(selected, 'destination', setFieldValue)}
                            hideOptions={isReadOnly(values)}
                          />
                        ) : (
                          <Input
                            id="cidadeDestino"
                            label="Cidade de Destino da Carga"
                            placeholder="Insira a cidade de destino da carga"
                            name="cidadeDestino"
                            value={values.cidadeDestino}
                            onChange={handleChange}
                            readonly
                            disabled={!values.cidadeDestino}
                          />
                        )}
                      </Grid>
                    </Card>
                    {isReadOnly(values) ? null : (
                      <Box gap={1}>
                        <Input
                          id="numeroNota"
                          name="numeroNota"
                          label="Número da Nota"
                          placeholder="Insira o número da nota"
                          value={values.numeroNota}
                          onChange={handleChange}
                          error={touched.numeroNota && errors.numeroNota}
                          readonly={isReadOnly(values)}
                        />
                        <Input
                          id="serieNota"
                          name="serieNota"
                          label="Série da Nota"
                          placeholder="Insira a série da nota"
                          value={values.serieNota}
                          onChange={handleChange}
                          error={touched.serieNota && errors.serieNota}
                          readonly={isReadOnly(values)}
                        />
                        <Input
                          id="dataIni"
                          label="Período de Emissão (Início)"
                          type="date"
                          placeholder="00/00/0000"
                          name="dataIni"
                          value={values.dataIni}
                          onChange={handleChange}
                          error={touched.dataIni && errors.dataIni}
                          readonly={isReadOnly(values)}
                        />
                        <Input
                          id="dataFim"
                          label="Período de Emissão (Fim)"
                          type="date"
                          placeholder="00/00/0000"
                          name="dataFim"
                          value={values.dataFim}
                          onChange={handleChange}
                          error={touched.dataFim && errors.dataFim}
                          readonly={isReadOnly(values)}
                        />
                        <IconButton
                          size={1.25}
                          icon={<FontAwesomeIcon icon={faSearch} />}
                          style={{ alignSelf: 'flex-end' }}
                          onClick={e => getFiscalNotes(e, values, setFieldValue)}
                          disabled={isReadOnly(values)}
                        />
                      </Box>
                    )}
                    <Card label="Documentos Fiscais Vinculados">
                      {arrayHasContent(values.notasRef) ? (
                        <Box direction="column" width="100%">
                          <InputLabel>Chaves(s) de Acesso</InputLabel>
                          <Box style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '0.25rem' }} width="100%" gap={0.5}>
                            {values.notasRef.map(({ chave }) => (
                              <Chip label={chave} onDelete={e => handleRemoveFiscalNote(e, chave, values, setFieldValue)} />
                            ))}
                          </Box>
                        </Box>
                      ) : (
                        <Input
                          id="notasRef"
                          label="Chaves(s) de Acesso"
                          placeholder="Insira as chaves de acesso"
                          disabled={!arrayHasContent(values.notasRef)}
                          name="notasRef"
                          error={touched.notasRef && errors.notasRef}
                          readonly
                        />
                      )}
                      <Box gap={1}>
                        <Input
                          id="qtdNfeVinculadas"
                          label="Quantidade de documentos"
                          placeholder="Insira a quantidade de documentos"
                          type="number"
                          name="qtdNfeVinculadas"
                          value={values.notasRef.length}
                          disabled={!arrayHasContent(values.notasRef)}
                          readonly
                        />
                        <Input
                          id="valorCarga"
                          startAdornment="R$"
                          label="Valor Total das Danfes"
                          placeholder="0,00"
                          name="valorCarga"
                          value={formatTotalValue(values.notasRef)}
                          disabled={!arrayHasContent(values.notasRef)}
                          readonly
                        />
                        <Input
                          id="pesoCarga"
                          label="Peso total da Carga"
                          placeholder="XXXXXXXXX"
                          name="pesoCarga"
                          value={values.pesoCarga}
                          onChange={handleChange}
                          error={touched.pesoCarga && errors.pesoCarga}
                          type="number"
                          endAdornment={
                            <OptionsAdornment
                              value={values.unMedida}
                              options={[
                                { label: 'KG', value: '01' },
                                { label: 'TON', value: '02' },
                              ]}
                              onSelect={selected => setFieldValue('unMedida', selected.value)}
                              disabled={isReadOnly(values)}
                            />
                          }
                          readonly={isReadOnly(values)}
                        />
                      </Box>
                    </Card>
                    <Card label="Dados do Veículo">
                      <Box gap={1}>
                        <Autocomplete
                          id="placaVeiculo"
                          label="Placa do Veículo"
                          placeholder="Insira a placa do veículo"
                          name="placaVeiculo"
                          value={values.placaVeiculo}
                          onChange={e => handlePlateChange(e, setFieldValue)}
                          error={touched.placaVeiculo && errors.veiculoId}
                          flex={1}
                          readonly={isReadOnly(values)}
                          maxlength={7}
                          isLoading={vehicleOptions.isLoading}
                          options={vehicleOptions.data}
                          nameKey="placaVeiculo"
                          onSelect={selected => handleSelectVehicle(selected, setFieldValue)}
                          onKeyDown={e => handleVehicleOnKeyDown(e, setFieldValue, values)}
                          hideOptions={isReadOnly(values)}
                        />
                        <Input
                          id="descricao"
                          label="Descrição"
                          placeholder="Insira a descrição do veículo"
                          name="descricao"
                          value={values.descricao}
                          onChange={handleChange}
                          flex={3}
                          readonly
                          disabled={!values.descricao}
                        />
                      </Box>
                      <Grid columns={4}>
                        <Input
                          id="renavam"
                          type="number"
                          label="Código Renavam"
                          placeholder="Insira o código de Renavam (11 dig.)"
                          name="renavam"
                          value={values.renavam}
                          onChange={handleChange}
                          readonly
                          disabled={!values.renavam}
                        />
                        <Input
                          id="tara"
                          type="number"
                          min={0}
                          label="Tara em KG"
                          placeholder="Insira a tara em kg"
                          name="tara"
                          value={values.tara}
                          onChange={handleChange}
                          readonly
                          disabled={!values.tara}
                        />
                        <Input
                          id="capacidade"
                          type="number"
                          min={0}
                          label="Capacidade em KG"
                          placeholder="Insira a capacidade do veículo"
                          name="capacidade"
                          value={values.capacidade}
                          onChange={handleChange}
                          readonly
                          disabled={!values.capacidade}
                        />
                        <Select
                          id="ufVeiculo"
                          name="ufVeiculo"
                          label="UF de Licenciamento"
                          placeholder="Escolha o UF"
                          value={values.ufVeiculo}
                          onChange={handleChange}
                          readonly
                          disabled={!values.ufVeiculo}
                          options={ufOptions}
                        />
                      </Grid>
                      <Grid columns={2}>
                        <Select
                          id="tipoRodado"
                          name="tipoRodado"
                          label="Tipo de Rodado"
                          placeholder="Escolha o tipo de rodado"
                          value={values.tipoRodado}
                          onChange={handleChange}
                          readonly
                          disabled={!values.tipoRodado}
                          options={wheeledOptions}
                        />
                        {values.tipoRodado === 3 && (
                          <Input
                            id="placaReboque"
                            name="placaReboque"
                            label="Placa do Reboque"
                            placeholder="Insira a placa do reboque"
                            value={values.placaReboque}
                            maxlength={7}
                            disabled={!values.placaReboque}
                            readonly
                          />
                        )}
                        <Select
                          id="tipoCarroceria"
                          name="tipoCarroceria"
                          label="Tipo de Carroceria"
                          placeholder="Escolha o tipo de carroceria"
                          value={values.tipoCarroceria}
                          onChange={handleChange}
                          readonly
                          disabled={!values.tipoCarroceria}
                          options={bodyworkOptions}
                        />
                      </Grid>
                    </Card>
                    <Card label="Dados do Motorista">
                      <Box gap={1}>
                        <Autocomplete
                          id="cpfMotorista"
                          label="CPF"
                          placeholder="Insira o CPF"
                          name="cpfMotorista"
                          value={values.cpfMotorista}
                          onChange={e => handleCpfCnpjChange(e, setFieldValue)}
                          error={touched.cpfMotorista && errors.motoristaId}
                          mask={unmaskValue(values.cpfMotorista).length > 11 ? '99.999.999/9999-99' : '999.999.999-99'}
                          maxlength={18}
                          readonly={isReadOnly(values)}
                          isLoading={driverOptions.isLoading}
                          options={driverOptions.data}
                          nameKey="cpf"
                          valueKey="cpf"
                          optionFormatter={formatDriverOption}
                          onSelect={selected => handleSelectDriver(selected, setFieldValue)}
                          onKeyDown={e => handleDriverOnKeyDown(e, setFieldValue, values)}
                          hideOptions={isReadOnly(values)}
                        />
                        <Input
                          id="nomeMotorista"
                          label="Nome do Motorista/Entregador"
                          placeholder="Insira o nome do motorista ou entregador"
                          name="nomeMotorista"
                          value={values.nomeMotorista}
                          onChange={handleChange}
                          disabled={!values.nomeMotorista}
                          readonly
                        />
                      </Box>
                    </Card>
                    <Card label="Observações">
                      <Input
                        id="infAdc"
                        label="Informações ao Fisco"
                        placeholder="Insira informações relevantes ao fisco"
                        name="infAdc"
                        value={values.infAdc}
                        onChange={handleChange}
                        error={touched.infAdc && errors.infAdc}
                        readonly={isReadOnly(values)}
                      />
                    </Card>
                  </Card>
                </Card>
                {sefazDialog.open && (
                  <Dialog
                    title="Envio ao Sefaz"
                    isOpen={sefazDialog.open}
                    setIsOpen={() => setSefazDialog(prev => ({ ...prev, open: false }))}
                    buttons={[
                      {
                        label: 'Salvar para Mais Tarde',
                        type: 'button',
                        onClick: () => generateMDFe(),
                      },
                      {
                        label: 'Confirmar',
                        type: 'button',
                        onClick: () => generateMDFe(generateMDFeXML),
                      },
                    ]}
                  >
                    <span>O MDFe será enviado ao Sefaz. Deseja confirmar o envio?</span>
                  </Dialog>
                )}
              </Form>
            )}
          </Formik>
        </LoadingOverlay>
      </LinxPostos>
      <Alert isOpen={alert.open} message={alert.message} variant={alert.variant} onClose={closeAlert} />
    </>
  );
};

export default MDFeForm;
