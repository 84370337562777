import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';
import { getDadosLogin, getNomePagina } from '../../../../shared/utils/Utils';

export class ApiBuscarSolicitacoesPorFiltrosService {
  urlLinxCore = null;

  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  execute = async (filtros) => {
    let usuario = getDadosLogin();
    let estaCod = usuario.codigoEstabelecimento;

    if (!estaCod) throw new Error('Código do estabelecimento não informado!');


    // Adiciona o estaCod aos filtros
    filtros.estaCod = estaCod;

    // Cria um objeto URLSearchParams para lidar com os parâmetros de consulta
    const params = new URLSearchParams();

    // Adiciona cada filtro ao URLSearchParams
    for (const key in filtros) {
      if (Object.prototype.hasOwnProperty.call(filtros, key)) {
        const value = filtros[key];
        if (Array.isArray(value)) {
          value.forEach(val => params.append(key, val)); // Adiciona cada valor do array individualmente
        } else {
          params.append(key, value);
        }
      }
    }

    try {
      const resp = await this.urlLinxCore.get('/SolReserva/BuscarSolicitacoesPorFiltros/', {
        params: params // Passa os parâmetros corretamente formatados
      });

      return resp.data || [];
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  };




  normalizeDateString(obj) {
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        if (!element) continue;
        if (Array.isArray(element)) {
          //console.log('Name/Array', `${key} / ${element}`);
        }
        if (element.constructor.name === 'Date') {
          //console.log('Name/Date', `${key} / ${element}`);
        }
        if (element.constructor.name === 'String' && element.match(/^[0-9]{4}-[0-1][0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9](?:\.[0-9]{1,3})?$/)) {
          //console.log('Name/String', `${key}`, element);
          obj[key] += 'Z';
        }
      }
    }
  }
}
