import React from 'react';
import { StyledButton } from './styles';

const Button = ({ text, onClick = () => {}, isLoading, variant = 'contained', children, ...props }) => {
  return (
    <StyledButton onClick={onClick} variant={variant} {...props}>
      {text || children}
    </StyledButton>
  );
};

export default Button;
