import { AxiosErrorService } from '../../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../../services/core/urlLinxCore.service';



export class ApiIntegrarVtexTrackingService {
  urlLinxCore = null;

  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  execute = async ({ pedidosSelecionados, tipoIntegracao }) => {
    try {
      // Convertendo os números para string
      const osIdsString = pedidosSelecionados.map(id => id.toString());
      
      const resp = await this.urlLinxCore.post(
        `/IntegraVtexTracking/IntegrarVtexTracking?tipoIntegracao=${tipoIntegracao}`,
        osIdsString  // Enviando o array de strings diretamente
      );
      return resp.data;
    } catch (er) {
      console.error('ApiIntegrarVtexTrackingService Error:', er);
      new AxiosErrorService().execute(er);
      throw er;
    }
  };
}