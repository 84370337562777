import React from 'react';
import { StyledOverlay, StyledSpinner } from './styles';

const LoadingOverlay = ({ isLoading, children }) => {
  return (
    <>
      {isLoading && (
        <StyledOverlay>
          <StyledSpinner />
        </StyledOverlay>
      )}
      {children}
    </>
  );
};

export default LoadingOverlay;
