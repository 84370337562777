import React, { useEffect, useState } from 'react';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { Alert, Autocomplete, Box, Button, Card, Grid, Input, Spinner } from '../../../components/new';
import { Form, Formik } from 'formik';
import { formInitialData } from './formInitialData';
import { validationSchema } from './validationSchema';
import { menus } from '../../../shared/constants/MenuConstants';
import { getDadosLogin, getNomePagina } from '../../../shared/utils/Utils';
import { create, fetchCEP, fetchCitiesByUF, index } from '../../../services/drivers/index.service';
import { maskInput, unmaskValue } from '../../../shared/utils/masks';
import { debounce, removeEmptyValues } from '../../../shared/utils';
import { getDadosEmpresa } from '../../configuracoes/empresas/Empresas.service';
import { ufOptions } from '../../../shared/options';

const PATH_MENU = menus.COD_13296;

const establishmentCode = getDadosLogin().codigoEstabelecimento || 1;

const DriverForm = () => {
  const [alert, setAlert] = useState({ open: false, message: null, variant: null });
  const [cities, setCities] = useState({ isLoading: false, data: [] });
  const [drivers, setDrivers] = useState({ isLoading: false, data: [] });
  const [cepInfo, setCepInfo] = useState({ isLoading: false, data: [] })
  const [enterpriseCode, setEnterpriseCode] = useState(null)

  const getDrivers = debounce(async search => {
    setDrivers({ isLoading: true, data: [] });
    try {
      const { data } = await index(search, establishmentCode, enterpriseCode);
      setDrivers(prev => ({ ...prev, data: data }));
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao buscar lista de motoristas.', variant: 'error' });
    } finally {
      setDrivers(prev => ({ ...prev, isLoading: false }));
    }
  }, 500);

  const sanitizeFormValues = values => {
    const sanitizedValues = removeEmptyValues(values);

    if (sanitizedValues.cpf) {
      sanitizedValues.cpf = unmaskValue(sanitizedValues.cpf);
    }

    if (sanitizedValues.telefone) {
      sanitizedValues.telefone = unmaskValue(sanitizedValues.telefone);
    }

    if (sanitizedValues.cep) {
      sanitizedValues.cep = unmaskValue(sanitizedValues.cep);
    }

    sanitizedValues.emprCod = enterpriseCode
    return sanitizedValues;
  };

  const handleSubmit = async values => {
    const sanitizedValues = sanitizeFormValues(values);
    try {
      await create(sanitizedValues);
      setAlert({ open: true, message: `Motorista ${values.id ? 'editado' : 'cadastrado'} com sucesso!`, variant: 'success' });
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: `Erro ao ${values.id ? 'editar' : 'cadastrar'} motorista.`, variant: 'error' });
    }
  };

  const handleCPFChange = ({ target }, setFieldValue) => {
    setFieldValue('cpf', target.value);
    getDrivers(unmaskValue(target.value));
  };

  const handleNameChange = ({ target }, setFieldValue) => {
    setFieldValue('nome', target.value);
    getDrivers(target.value);
  };

  const handleSelectDriver = (selectedDriver, setFieldValue) => {
    if (!selectedDriver) return
    Object.entries(selectedDriver).forEach(([key, value]) => {
      if (value !== undefined) {
        if (key === 'dataNascimento') {
          const date = new Date(value);
          value = date.toISOString().split('T')[0];
        }
        if (key === 'uf' && value === 0) {
          value = ''
        }
        setFieldValue(key, value);
      }
    });
  };

  const handleCpfOnKeyDown = (event, setFieldValue, resetForm, values) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.target.value.length === 14) {
        const selected = (drivers.data || []).find(({ cpf }) => unmaskValue(cpf) === unmaskValue(event.target.value));
        if (selected) {
          handleSelectDriver(selected, setFieldValue);
        } else {
          const newFormData = { ...formInitialData, cpf: event.target.value };
          handleSelectDriver(newFormData, setFieldValue);
          setAlert({ open: true, message: 'Nenhum registro encontrado para este CPF.', variant: 'warning' });
        }
      }
    } else if (event.key === 'Backspace' && values.motoristaId) {
      event.preventDefault();
      resetForm()
    }
  };

  const handleUFChange = ({ target }, setFieldValue) => {
    const upperCasedValue = target.value.toUpperCase();
    setFieldValue('uf', upperCasedValue);
    setFieldValue('cidade', '')
    const matchingUF = (ufOptions || []).find(({ label }) => label.slice(0, 2) === upperCasedValue)
    if (matchingUF) {
      handleSelectUF(matchingUF, setFieldValue)
    }
  }

  const handleSelectUF = async (selectedUF, setFieldValue) => {
    setFieldValue('cidade', '')
    setFieldValue('uf', selectedUF.id);
    setCities({ isLoading: true, data: [] });
    try {
      const { data } = await fetchCitiesByUF(selectedUF.id);
      setCities(prev => ({ ...prev, data: data.itens }));
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao buscar cidades.', variant: 'error' });
    } finally {
      setCities(prev => ({ ...prev, isLoading: false }));
    }
  };

  const getCepInfo = async (cep) => {
    setCepInfo(prev => ({ ...prev, isLoading: true }))
    try {
      const { data } = await fetchCEP(cep);
      if (!data.item.cep) {
        setAlert({ open: true, message: 'Sem dados para o CEP informado.', variant: 'warning' });
        return;
      }
      setCepInfo(prev => ({ ...prev, data: [data.item] }))
    } catch (error) {
      console.log(error);
      setAlert({ open: true, message: 'Erro ao buscar dados do CEP.', variant: 'error' });
    } finally {
      setCepInfo(prev => ({ ...prev, isLoading: false }))
    }
  }

  const handleCEPChange = ({ target }, setFieldValue) => {
    setFieldValue('cep', target.value)
    const unmaskedCEP = unmaskValue(target.value)
    if (unmaskedCEP.length === 8) {
      getCepInfo(unmaskedCEP);
    } else {
      setFieldValue('bairro', '')
      setFieldValue('logradouro', '')
      setFieldValue('endereco', '')
      setFieldValue('uf', '')
      setFieldValue('cidade', '')
    }
  }

  const handleSelectCEP = (selectedCEP, setFieldValue) => {
    const { bairro, cep, enderecoCompleto, logradouro, uf, municipio } = selectedCEP
    setFieldValue('cep', cep)
    setFieldValue('bairro', bairro)
    setFieldValue('logradouro', logradouro)
    setFieldValue('endereco', enderecoCompleto)
    setFieldValue('uf', uf.id)
    setFieldValue('cidade', municipio.descricao)
  }

  const closeAlert = () => {
    setAlert({ open: false, message: null, variant: null });
  };

  const getEntrepriseInfo = async () => {
    try {
      const { data } = await getDadosEmpresa()
      setEnterpriseCode(data.data.id)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getEntrepriseInfo();
  }, []);

  const handleKeyDownUF = (e, setFieldValue) => {
    if (e.key === 'Backspace') {
      e.preventDefault()
      setFieldValue('uf', '')
      setFieldValue('cidade', '')
      setCities({ isLoading: false, data: [] })
    }
  }

  const formatDriverOption = (option) => `${maskInput(option.cpf, "999.999.999-99")} - ${option.nome}`

  return (
    <>
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <Formik initialValues={formInitialData} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, handleChange, errors, touched, setFieldValue, isSubmitting, resetForm }) => (
            <Form>
              <Card marginTop={2} gap={2} marginBottom={3}>
                <Box justifyContent="space-between" alignItems="center">
                  <h1>Cadastro de Motorista</h1>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? <Spinner /> : 'Salvar'}
                  </Button>
                </Box>
                <Card>
                  <h3>Dados do Motorista</h3>
                  <Grid columns={3}>
                    <Autocomplete
                      label="Nome*"
                      placeholder="Insira o nome"
                      name="nome"
                      value={values.nome}
                      error={touched.nome && errors.nome}
                      onChange={e => handleNameChange(e, setFieldValue)}
                      readOnly={isSubmitting}
                      onSelect={selected => handleSelectDriver(selected, setFieldValue)}
                      isLoading={drivers.isLoading}
                      options={drivers.data}
                      nameKey="nome"
                      valueKey="nome"
                      optionFormatter={formatDriverOption}
                      disableSpecialCharacters
                    />
                    <Autocomplete
                      label="CPF*"
                      placeholder="Insira o CPF"
                      name="cpf"
                      value={values.cpf}
                      mask="999.999.999-99"
                      error={touched.cpf && errors.cpf}
                      onChange={e => handleCPFChange(e, setFieldValue)}
                      readOnly={isSubmitting}
                      maxlength={14}
                      onSelect={selected => handleSelectDriver(selected, setFieldValue)}
                      onKeyDown={e => handleCpfOnKeyDown(e, setFieldValue, resetForm, values)}
                      isLoading={drivers.isLoading}
                      options={drivers.data}
                      nameKey="cpf"
                      valueKey="cpf"
                      optionFormatter={formatDriverOption}
                    />
                    <Input
                      label="Data de Nascimento"
                      type="date"
                      name="dataNascimento"
                      value={values.dataNascimento}
                      error={touched.dataNascimento && errors.dataNascimento}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                      max="9999-12-31"
                    />
                    <Input
                      label="Identidade"
                      placeholder="Insira o RG (Identidade)"
                      name="identidade"
                      value={values.identidade}
                      error={touched.identidade && errors.identidade}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                      disableSpecialCharacters
                    />
                    <Input
                      label="Órgão Emissor"
                      placeholder="Ex: SSP-SP"
                      name="orgaoEmissor"
                      value={values.orgaoEmissor}
                      error={touched.orgaoEmissor && errors.orgaoEmissor}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                    />
                  </Grid>
                </Card>

                <Card>
                  <h3>Endereço</h3>
                  <Grid columns={3}>
                    <Autocomplete
                      label="CEP"
                      placeholder="Insira o CEP"
                      name="cep"
                      value={values.cep}
                      mask="99.999-999"
                      error={touched.cep && errors.cep}
                      onChange={e => handleCEPChange(e, setFieldValue)}
                      readOnly={isSubmitting}
                      maxlength={10}
                      nameKey='cep'
                      options={cepInfo.data}
                      isLoading={cepInfo.isLoading}
                      onSelect={selected => handleSelectCEP(selected, setFieldValue)}
                    />
                    <Input
                      label="Logradouro"
                      placeholder="Ex: Av, Rua, etc."
                      name="logradouro"
                      value={values.logradouro}
                      error={touched.logradouro && errors.logradouro}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                      disableSpecialCharacters
                    />
                    <Input
                      label="Endereço"
                      placeholder="Insira o endereço"
                      name="endereco"
                      value={values.endereco}
                      error={touched.endereco && errors.endereco}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                      disableSpecialCharacters
                    />
                    <Input
                      label="Número"
                      placeholder="Insira o número"
                      name="numero"
                      value={values.numero}
                      error={touched.numero && errors.numero}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                      disableSpecialCharacters
                    />
                    <Input
                      label="Complemento"
                      placeholder="Insira o complemento (Se houver)"
                      name="complemento"
                      value={values.complemento}
                      error={touched.complemento && errors.complemento}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                      disableSpecialCharacters
                    />
                    <Input
                      label="Bairro"
                      placeholder="Insira o bairro"
                      name="bairro"
                      value={values.bairro}
                      error={touched.bairro && errors.bairro}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                      disableSpecialCharacters
                    />
                      <Autocomplete
                        label="UF"
                        placeholder="UF"
                        name="uf"
                        value={values.uf}
                        error={touched.uf && errors.uf}
                        onChange={e => handleUFChange(e, setFieldValue)}
                        options={ufOptions}
                        flex={1}
                        onSelect={selected => handleSelectUF(selected, setFieldValue)}
                        onKeyDown={e => handleKeyDownUF(e, setFieldValue)}
                        maxlength={2}
                      />
                      <Autocomplete
                        label="Cidade"
                        placeholder={cities.isLoading ? 'Buscando cidades...' : 'Selecione a cidade'}
                        name="cidade"
                        value={values.cidade}
                        error={touched.cidade && errors.cidade}
                        onChange={(e) => setFieldValue('cidade', e.target.value.toUpperCase())}
                        options={cities.data}
                        flex={2}
                        nameKey="descricao"
                        disabled={!values.uf || cities.isLoading}
                        onSelect={selected => setFieldValue('cidade', selected.descricao)}
                        isLoading={cities.isLoading}
                      />
                    <Input
                      label="Telefone"
                      placeholder="Insira o telefone"
                      name="telefone"
                      value={values.telefone}
                      mask="(99) 9 9999-9999"
                      error={touched.telefone && errors.telefone}
                      onChange={handleChange}
                      flex={4}
                      readOnly={isSubmitting}
                    />
                    <Input
                      label="E-mail"
                      placeholder="Insira o e-mail"
                      name="email"
                      value={values.email}
                      error={touched.email && errors.email}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                      type="email"
                    />
                    <Input
                      label="Site"
                      placeholder="Insira o site"
                      name="site"
                      value={values.site}
                      error={touched.site && errors.site}
                      onChange={handleChange}
                      readOnly={isSubmitting}
                    />
                  </Grid>
                </Card>
              </Card>
            </Form>
          )}
        </Formik>
      </LinxPostos>
      <Alert isOpen={alert.open} message={alert.message} variant={alert.variant} onClose={closeAlert} />
    </>
  );
};

export default DriverForm;
