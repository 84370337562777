import { AxiosErrorService } from '../../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../../services/core/urlLinxCore.service';



export class ApiCancelarIntegrarTrackingService {
  urlLinxCore = null;

  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  execute = async ({ pedidosSelecionados, tipoIntegracao }) => {
    try {
      // Convertendo os números para string
      console.log('osids:', pedidosSelecionados);
      const osIdsString = pedidosSelecionados.map(id => id.toString());

      const resp = await this.urlLinxCore.post(
        `/IntegraVtexTracking/CancelarIntegrarTracking`,
        osIdsString  // Enviando o array de strings diretamente
      );


      return resp.data;
    } catch (er) {
      console.error('ApiCancelarIntegrarTrackingService Error:', er);
      await new AxiosErrorService().execute(er);
      throw er;
    }
  };
}
