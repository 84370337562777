import * as Yup from 'yup';
import { unmaskValue } from '../../../shared/utils/masks';
import { validateCEP, validateCPF } from '../../../shared/utils/schemaValidation';

const validateCPFValue = value => {
  const unmaskedValue = unmaskValue(value);
  return validateCPF(unmaskedValue);
};

const validateCEPValue = value => {
  if (!value) return true; // campo não é obrigatório
  const unmaskedValue = unmaskValue(value);
  return validateCEP(unmaskedValue);
};

const validatePhoneNumber = value => {
  if (!value) return true; // campo não é obrigatório
  const unmaskedValue = unmaskValue(value);
  return unmaskedValue.length === 11;
};

const validateEmail = value => {
  if (!value) return true; // campo não é obrigatório
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};

const validateRG = value => {
  if (!value) return true; // campo não é obrigatório
  return value.length >= 6;
};

const validateSite = value => {
  if (!value) return true; // Campo não obrigatório
  const urlRegex = /^(https?:\/\/)?(www\.)?[^\s$.?#].[^\s]*$/;
  return urlRegex.test(value);
};

export const validationSchema = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('CPF validation', 'CPF inválido', validateCPFValue),
  cep: Yup.string().test('CEP validation', 'CEP inválido', validateCEPValue),
  telefone: Yup.string().test('Telefone validation', 'Número de telefone inválido', validatePhoneNumber),
  email: Yup.string().test('Email validation', 'Email inválido', validateEmail),
  identidade: Yup.string().test('RG validation', 'Mínimo de 6 caracteres', validateRG),
  logradouro: Yup.string(),
  endereco: Yup.string(),
  numero: Yup.string(),
  complemento: Yup.string(),
  bairro: Yup.string(),
  site: Yup.string().test('Site validation', 'Endereço de site inválido', validateSite),
});
