import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { alert } from 'devextreme/ui/dialog';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';
import { fetchFiltroOsInit, selectFiltroOs, useFiltroOs } from '../../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { ApiListarOsService } from '../../usercases/ApiListarOs.service';
import { ApiVerificaHabilitadoVtexTrackingService } from '../../../consignado-comodato-nova-os/faturamento/usercases/ApiVerificaHabilitadoVtexTracking.service';
import { ApiVerificarStatusTrackingService } from './../../usercases/ApiVerificarStatusTracking.service';
import { ButtonOpType } from '../ButtonOpType';
import { CheckboxStatus } from '../CheckboxStatus';
import { DropboxOrigens } from '../DropboxOrigens';
import { DropboxTiposPedidos } from '../DropboxTiposPedidos';
import { FloatDateInput } from '../FloatDateInput';
import { FloatLabelInput } from '../FloatLabelInput';
import { FloatTimeInput } from '../FloatTimeInput';
import { fabricarFiltroString } from './FabricarFiltroString';
import FiltroAvancado from './FiltroAvancado';
import { CheckBox } from 'devextreme-react';

export const FiltroLista = forwardRef(({ setSearching, setSearchingMessage, activeKey }, ref) => {
  const { changeValue } = useFiltroOs();
  const { values } = useSelector(selectFiltroOs);
  const dispatch = useDispatch();
  const origens = useSelector(selectConsignadoComodatoSharedData).origens;
  const pagamentos = useSelector(selectConsignadoComodatoSharedData).pagamentos;
  const tiposFrete = useSelector(selectConsignadoComodatoSharedData).tiposFrete;
  const tiposPedidos = useSelector(selectConsignadoComodatoSharedData).tiposPedidos;
  const [resolution, setResolution] = useState([]);

  useEffect(() => {
    const updateColumns = () => {
      setResolution(window.innerWidth);
    };
    window.addEventListener('resize', updateColumns);
    updateColumns();

    return () => window.removeEventListener('resize', updateColumns);
  }, []);

  const onLimparFiltro = useCallback(async () => {
    dispatch(fetchFiltroOsInit());
  }, [dispatch]);

  const onAplicarFiltro = useCallback(async () => {
    const service = new ApiListarOsService();
    try {
      setSearching(true);
      setSearchingMessage('Carregando Pedidos...');
      const filter = {
        itensPorPagina: values.itensPorPagina || 25,
        pagina: values.pagina || 1,
      };
      if (values.dataPedidoDe && values.dataPedidoAte) {
        validarData(values.dataPedidoDe, values.dataPedidoAte, 'data de pedido');
        filter.dataPedidoDe = new Date(values.dataPedidoDe).toISOString();
        filter.dataPedidoAte = new Date(values.dataPedidoAte).toISOString();
      }
      if (values.dataPedidoDe && !values.dataPedidoAte) {
        filter.dataPedidoDe = new Date(values.dataPedidoDe).toISOString();
      }
      if (values.dataEventoDe && values.dataEventoAte) {
        validarData(values.dataEventoDe, values.dataEventoAte, 'data de evento');
        filter.dataEventoDe = new Date(values.dataEventoDe).toISOString();
        filter.dataEventoAte = new Date(values.dataEventoAte).toISOString();
      }
      if (values.dataEventoDe && !values.dataEventoAte) {
        filter.dataEventoDe = new Date(values.dataEventoDe).toISOString();
      }
      if (values.dataFaturamentoDe && values.dataFaturamentoAte) {
        validarData(values.dataFaturamentoDe, values.dataFaturamentoAte, 'data de evento');
        filter.dataFaturamentoDe = new Date(values.dataFaturamentoDe).toISOString();
        filter.dataFaturamentoAte = new Date(values.dataFaturamentoAte).toISOString();
      }
      if (values.dataFaturamentoDe && !values.dataFaturamentoAte) {
        filter.dataFaturamentoDe = new Date(values.dataFaturamentoDe).toISOString();
      }
      if (values.dataEntregaRetiradaDe && values.dataEntregaRetiradaAte) {
        validarData(values.dataEntregaRetiradaDe, values.dataEntregaRetiradaAte, 'data de entrega/retirada');
        filter.dataEntregaRetiradaDe = new Date(values.dataEntregaRetiradaDe).toISOString();
        filter.dataEntregaRetiradaAte = new Date(values.dataEntregaRetiradaAte).toISOString();
      }
      if (values.dataEntregaRetiradaDe && !values.dataEntregaRetiradaAte) {
        filter.dataEntregaRetiradaDe = new Date(values.dataEntregaRetiradaDe).toISOString();
      }
      if (values.dataRecolhaDevolucaoDe && values.dataRecolhaDevolucaoAte) {
        validarData(values.dataRecolhaDevolucaoDe, values.dataRecolhaDevolucaoAte, 'data de Recolha/Devolução');
        filter.dataRecolhaDevolucaoDe = new Date(values.dataRecolhaDevolucaoDe).toISOString();
        filter.dataRecolhaDevolucaoAte = new Date(values.dataRecolhaDevolucaoAte).toISOString();
      }
      if (values.dataRecolhaDevolucaoDe && !values.dataRecolhaDevolucaoAte) {
        filter.dataRecolhaDevolucaoDe = new Date(values.dataRecolhaDevolucaoDe).toISOString();
      }
      if (values.numeroOs) filter.numeroOs = values.numeroOs;
      if (values.numeroNf) filter.numeroNf = values.numeroNf;
      if (values.dadosCliente) filter.cliente = values.dadosCliente;

      if ((values.status || []).length > 0) {
        filter.statusId = values.status.join('_');
      }
      if (values.tiposPedidos) {
        filter.statusTypeId = values.tiposPedidos;
      }
      if ((values.origens || []).length > 0) {
        filter.origensId = values.origens;
      }
      if ((values.saldoPendente || []).length > 0) {
        filter.saldoPendente = (values.saldoPendente || []).length > 0;
      }
      if ((values.comodatoPendente || []).length > 0) {
        filter.comodatoPendente = (values.comodatoPendente || []).length > 0;
      }
      if ((values.consignadoPendente || []).length > 0) {
        filter.consignadoPendente = (values.consignadoPendente || []).length > 0;
      }
      if ((values.locacaoPendente || []).length > 0) {
        filter.locacaoPendente = (values.locacaoPendente || []).length > 0;
      }
      if (values.produto) {
        filter.prodCod = values.produto.id;
      }
      if (values.pagamentos) {
        filter.pagamentos = values.pagamentos;
      }
      if (values.tiposFrete) {
        filter.tiposFrete = values.tiposFrete;
      }

      const response = await service.execute(filter, activeKey === 'itens');

    

      const habilitadoVtex = await new ApiVerificaHabilitadoVtexTrackingService().execute();

      const itensAtualizados = await Promise.all(
        response.itens.map(async (item) => {

          if (!habilitadoVtex) {
            // Caso o sistema não esteja habilitado, assume que não está integrado
            return {
              ...item,
              statusTracking: 'Não',
            };
          }


          try {
            const statusList = await new ApiVerificarStatusTrackingService().execute(item.uId);

            if (!Array.isArray(statusList) || statusList.length === 0) {
              // Caso não tenha retornos
              return {
                ...item,
                statusTracking: 'Não',
              };
            }
          
            // Obtém o último retorno (ordenado por data crescente)
            const ultimoStatus = statusList[statusList.length - 1];
     
            if (ultimoStatus.tipoIntegracao === "CANCELADA") {
              // Último tipo de integração é 'Cancelado'
              return {
                ...item,
                statusTracking: 'Cancelada',
              };
            }
          
            if (ultimoStatus.status === 'ERRO') {
              // Último status é 'ERRO'
              return {
                ...item,
                statusTracking: 'Erro',
              };
            }
          
            if (
              (ultimoStatus.tipoIntegracao === "RECOLHA" || ultimoStatus.tipoIntegracao === "ENTREGA") &&
              ultimoStatus.status === 'Sucesso'
            ) {
              
              // Último tipo de integração é 1 ou 2 e status é 'SUCESSO'
              return {
                ...item,
                statusTracking: 'Sim',
              };
            }
          
            // Caso não se enquadre em nenhuma regra específica
            return {
              ...item,
              statusTracking: 'Não',
            };
          } catch (error) {
            console.error(`Erro ao verificar status do tracking para o pedido ${item.uId}:`, error);
            return {
              ...item,
              statusTracking: 'Não',
            };
          }
        })
      );

      if (values.statusIntegracaoTracking) {
        console.log('values.statusIntegracaoTracking', values.statusIntegracaoTracking);
        const filtroTracking = values.statusIntegracaoTracking;
        const itensFiltrados = itensAtualizados.filter((item) => {
          
          if (filtroTracking === 'Integrado') {
            return item.statusTracking === 'Sim';
          }
          if (filtroTracking === 'Pendente') {
            return item.statusTracking === 'Não';
          }
          return true; // Para o caso de "Todos"
        });

        
        console.log('itensFiltrados', itensFiltrados);
        // Atualiza os itens no Redux após aplicar o filtro
        if (activeKey === 'itens') {
          dispatch(changeValue({ fieldName: 'itensPedido', value: itensFiltrados }));
        } else {
          dispatch(changeValue({ fieldName: 'itens', value: itensFiltrados }));
        }
      }
      else{
      if (activeKey === 'itens') {
        dispatch(changeValue({ fieldName: 'itensPedido', value: itensAtualizados  }));
      } else {
        dispatch(changeValue({ fieldName: 'itens', value: itensAtualizados  }));
      }
      setSearching(false);
    } 
  }
  catch (error) {
    setSearching(false);
    alert(error.message, 'Erro');
  }
  }, [dispatch, changeValue, values, setSearching, setSearchingMessage, activeKey]);

  useImperativeHandle(
    ref,
    () => ({
      executeAplicarFiltro() {
        onAplicarFiltro();
      },
      filtroString() {
        return fabricarFiltroString(values, origens, pagamentos, tiposFrete, tiposPedidos);
      },
    }),
    [onAplicarFiltro, values, origens, pagamentos, tiposFrete, tiposPedidos],
  );

  const validarData = (dataDe, dataAte, fieldName) => {
    if (!dataDe || !dataAte) {
      throw new Error(`Informe a ${fieldName} de => até`);
    }
    if (dataDe > dataAte) {
      throw new Error(`A ${fieldName} "de" deve ser menor que a ${fieldName} "até"`);
    }
  };

  return (
    <>
      {values && (
        <Card>
          <Card.Body className="border pl-0">
            <Row className="m-0 p-0">
              <Col md={resolution > 1150 ? 4 : 8}>
                <Row className="m-0 p-0">
                  <Col md={4} className="m-0 p-0">
                    <FloatLabelInput type="text" value={values.numeroOs} onChange={changeValue} id="numeroOs" label="Nº Pedido" />
                  </Col>
                  <Col md={8}>
                    <DropboxOrigens />
                  </Col>
                  <Col md={4} className="m-0 p-0">
                    <FloatLabelInput className={'mt-1'} value={values.numeroNf} onChange={changeValue} id="numeroNf" label="NF-e" />
                  </Col>
                  <Col md={8}>
                    <FloatLabelInput className={'mt-1'} value={values.dadosCliente} onChange={changeValue} id="dadosCliente" label="Nome/CNPJ/CPF do Cliente" />
                  </Col>
                </Row>
              </Col>
              <Col md={resolution > 1150 ? 2 : 4} className="m-0 p-0">
                <Row className="m-0 p-0">
                  <Col md={12} className="pl-0 pr-0">
                    <FloatDateInput
                      displayFormat="dd/MM/yyyy"
                      placeholder="de:"
                      label="Data do Pedido"
                      value={values.dataPedidoDe}
                      id="dataPedidoDe"
                      onChange={changeValue}
                    />
                  </Col>
                  <Col md={12} className="pl-0 pr-0">
                    <FloatDateInput
                      displayFormat="dd/MM/yyyy"
                      label=""
                      placeholder="até:"
                      value={values.dataPedidoAte}
                      id="dataPedidoAte"
                      onChange={changeValue}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={resolution > 1150 ? 2 : 4} className="m-0 p-0" style={{ borderLeft: 'solid 1px #f0f0f0' }}>
                <Row className="m-0 p-0">
                  <Col md={8} className="m-0 p-0">
                    <FloatDateInput
                      isMinTime={true}
                      displayFormat="dd/MM/yyyy"
                      placeholder="de:"
                      label="Data do Evento"
                      value={values.dataEventoDe}
                      id="dataEventoDe"
                      onChange={changeValue}
                    />
                  </Col>
                  <Col md={4} className="pl-0 pr-0">
                    <FloatTimeInput placeholder="de:" label="Hora" value={values.dataEventoDe} id="dataEventoDe" onChange={changeValue} />
                  </Col>
                  <Col md={8} className="pl-2">
                    <FloatDateInput
                      isMinTime={false}
                      displayFormat="dd/MM/yyyy"
                      label=""
                      placeholder="até:"
                      value={values.dataEventoAte}
                      id="dataEventoAte"
                      onChange={changeValue}
                    />
                  </Col>
                  <Col md={4} className="pl-0 pr-0">
                    <FloatTimeInput placeholder="até:" label="Hora" value={values.dataEventoAte} id="dataEventoAte" onChange={changeValue} />
                  </Col>
                </Row>
              </Col>
              <Col md={resolution > 1150 ? 2 : 4} className="m-0 p-0" style={{ borderLeft: 'solid 1px #f0f0f0' }}>
                <Row className="m-0 p-0">
                  <Col className="p-0 m-0">
                    <Row className="m-0 p-0">
                      <Col md={8} className="pl-0 pr-0">
                        <FloatDateInput
                          isMinTime={true}
                          displayFormat="dd/MM/yyyy"
                          placeholder="de:"
                          label="Data Entr./Ret."
                          value={values.dataEntregaRetiradaDe}
                          id="dataEntregaRetiradaDe"
                          onChange={changeValue}
                        />
                      </Col>
                      <Col md={4} className="pl-0 pr-0">
                        <FloatTimeInput placeholder="de:" label="Hora" value={values.dataEntregaRetiradaDe} id="dataEntregaRetiradaDe" onChange={changeValue} />
                      </Col>
                      <Col md={8} className="pl-0 pr-0">
                        <FloatDateInput
                          isMinTime={false}
                          displayFormat="dd/MM/yyyy"
                          label=""
                          placeholder="até:"
                          value={values.dataEntregaRetiradaAte}
                          id="dataEntregaRetiradaAte"
                          onChange={changeValue}
                        />
                      </Col>
                      <Col md={4} className="pl-0 pr-0">
                        <FloatTimeInput
                          placeholder="de:"
                          label="Hora"
                          value={values.dataEntregaRetiradaAte}
                          id="dataEntregaRetiradaAte"
                          onChange={changeValue}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md={resolution > 1150 ? 2 : 4} className="m-0 p-0" style={{ borderLeft: 'solid 1px #f0f0f0' }}>
                <Row className="m-0 p-0">
                  <Col md={8} className="pl-0 pr-0">
                    <FloatDateInput
                      isMinTime={true}
                      displayFormat="dd/MM/yyyy"
                      placeholder="de:"
                      label={resolution > 1230 ? 'Data Recolha/Devolução' : 'Data Rec/Devolução'}
                      value={values.dataRecolhaDevolucaoDe}
                      id="dataRecolhaDevolucaoDe"
                      onChange={changeValue}
                    />
                  </Col>
                  <Col md={4} className="pl-0 pr-0">
                    <FloatTimeInput placeholder="de:" label="Hora" value={values.dataRecolhaDevolucaoDe} id="dataRecolhaDevolucaoDe" onChange={changeValue} />
                  </Col>
                  <Col md={8} className="pl-0 pr-0">
                    <FloatDateInput
                      isMinTime={false}
                      displayFormat="dd/MM/yyyy"
                      label=""
                      placeholder="até:"
                      value={values.dataRecolhaDevolucaoAte}
                      id="dataRecolhaDevolucaoAte"
                      onChange={changeValue}
                    />
                  </Col>
                  <Col md={4} className="pl-0 pr-0">
                    <FloatTimeInput placeholder="de:" label="Hora" value={values.dataRecolhaDevolucaoAte} id="dataRecolhaDevolucaoAte" onChange={changeValue} />
                  </Col>
                </Row>
              </Col>
              <Col md={resolution > 1100 ? 6 : 4}>
                <Row className="m-0 p-0">
                  <Col md={resolution > 1100 ? 3 : 6} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="em-aberto" statusCss="color-em-aberto" label="Em Aberto" statusId={1} />
                  </Col>
                  <Col md={resolution > 1100 ? 3 : 6} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="confirmado" statusId={2} label={resolution > 1185 ? 'Confirmado' : 'Confirm'} />
                  </Col>
                  <Col md={resolution > 1100 ? 3 : 6} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="carregado" statusId={3} label="Carregado" />
                  </Col>
                  <Col md={resolution > 1100 ? 3 : 6} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="entregue" statusId={4} label="Entregue" />
                  </Col>
                  <Col md={resolution > 1100 ? 3 : 6} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="recolhido" statusId={5} label="Recolhido" />
                  </Col>
                  <Col md={resolution > 1100 ? 3 : 6} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="recolhido-parcial" statusId={8} label={resolution > 1475 ? 'Recolhido Parcial' : 'Rec/Parc'} />
                  </Col>
                  <Col md={resolution > 1100 ? 3 : 6} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="cancelado" statusId={6} label="Cancelado" />
                  </Col>
                  <Col md={resolution > 1100 ? 3 : 6} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="fechado" statusId={7} label="Fechado" />
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
              <Col md={resolution > 1100 ? 5 : 7} className="mr-0 pr-0">
                <Row className="m-0 p-0">
                  {/* <Col md={3} className="m-0 p-1">
                    <ButtonOpType
                      className="w-100"
                      propertyName="statusType"
                      typeName="confirmado"
                      statusId={2}
                      label={resolution > 1380 ? 'Consignado' : 'Consig.'}
                    />
                  </Col>
                  <Col md={3} className="m-0 p-1">
                    <ButtonOpType
                      className="w-100"
                      propertyName="statusType"
                      typeName="confirmado"
                      statusId={3}
                      label={resolution > 1290 ? 'Comodato' : 'Comod.'}
                    />
                  </Col> */}
                  <Col md={8} className="m-0 p-0">
                    <DropboxTiposPedidos />
                  </Col>
                  <Col md={4} className="m-0 p-1">
                    <button onClick={onAplicarFiltro} className="btn btn-primary w-100">
                      <i className="icon icon-lx-search"></i> APLICAR FILTRO
                    </button>
                  </Col>
                  <Col md={2} className="m-0 p-0 pl-1 pr-1">
                    <ButtonOpType
                      className="w-100 h-100 m-0"
                      buttonClass="btn-fs-9"
                      propertyName="saldoPendente"
                      typeName="cancelado"
                      statusId={1}
                      label="Saldo em Aberto"
                    />
                  </Col>
                  <Col md={2} className="m-0 p-0 pl-1 pr-1">
                    <ButtonOpType
                      className="w-100 h-100 m-0"
                      buttonClass="btn-fs-9"
                      propertyName="consignadoPendente"
                      typeName="cancelado"
                      statusId={1}
                      label="Pendente Consignado"
                    />
                  </Col>
                  <Col md={2} className="m-0 p-0 pl-1 pr-1">
                    <ButtonOpType
                      className="w-100 h-100 m-0"
                      buttonClass="btn-fs-9"
                      propertyName="comodatoPendente"
                      typeName="cancelado"
                      statusId={1}
                      label="Pendente Comodato"
                    />
                  </Col>
                  <Col md={2} className="m-0 p-0 pl-1 pr-1">
                    <ButtonOpType
                      className="w-100 h-100 m-0"
                      buttonClass="btn-fs-9"
                      propertyName="locacaoPendente"
                      typeName="cancelado"
                      statusId={1}
                      label="Pendente Locação"
                    />
                  </Col>
                  <Col md={4} className="m-0 p-1">
                    <button onClick={onLimparFiltro} className="btn btn-secondary  w-100 h-100">
                      <i className="icon lg icon-lx-close"></i> LIMPAR
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3 ml-1">
              <Col>
                <Card>
                  <Card.Body className="m-0">
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <h2 className="w-100">Mais filtros</h2>
                      <CheckBox
                        defaultValue={false}
                        style={{ fonstSize: '5px !important' }}
                        value={values.filtrosAvancados || false}
                        onValueChanged={e => {
                          dispatch(changeValue({ fieldName: 'filtrosAvancados', value: e.value }));
                        }}
                        
                      />
                    </div>
                  </Card.Body>
                  {values.filtrosAvancados && (
                    <>
                      <hr></hr>
                      <Card.Body>
                        <FiltroAvancado resolution={resolution}></FiltroAvancado>
                      </Card.Body>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
           
          </Card.Body>
          <span className="text-center" style={{ margin: '0 auto', fontSize: '12px', color: 'gray', marginTop: '10px', marginBottom: '10px' }}>
            {fabricarFiltroString(values, origens, pagamentos, tiposFrete, tiposPedidos)}
          </span>
        </Card>
      )}
    </>
  );
});
