import { DataGrid, DropDownBox } from 'devextreme-react';
import { Column, FilterRow, Scrolling, Selection } from 'devextreme-react/data-grid';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFiltroOs, useFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { DropDownImage } from '../../consignado-comodato-nova-os/components/drop-down-image';
import styles from './Component.module.scss';

const statusIntegracaoOptions = [
  { id: 'Integrado', nome: 'Integrado' },
  { id: 'Pendente', nome: 'Pendente' }
];

export function DropboxStatusIntegracaoTracking({ statusCss, className, label }) {
  const [opened, setOpened] = useState(false);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const { values } = useSelector(selectFiltroOs);
  const dispatch = useDispatch();
  const { changeValue } = useFiltroOs();

  useEffect(() => {
    if (values.statusIntegracaoTracking) {
      setSelectedItemKeys([values.statusIntegracaoTracking]);
    } else {
      setSelectedItemKeys([]);
    }
  }, [values.statusIntegracaoTracking]);

  const onSelectedItemKeysChange = useCallback(
    args => {
      if (args.selectedRowKeys.length === 0) {
        dispatch(changeValue({ fieldName: 'statusIntegracaoTracking', value: '' }));
      } else {
        const selectedStatus = args.selectedRowKeys[0];
        if (selectedStatus !== values.statusIntegracaoTracking) {
          dispatch(changeValue({ fieldName: 'statusIntegracaoTracking', value: selectedStatus }));
        }
      }
    },
    [dispatch, changeValue, values.statusIntegracaoTracking]
  );

  const onValueChanged = useCallback((e) => {
    if (!e.value || e.value.length === 0) {
      dispatch(changeValue({ fieldName: 'statusIntegracaoTracking', value: '' }));
    }
  }, [dispatch, changeValue]);

  return (
    <div className={className}>
      <DropDownBox
        showClearButton={true}
        className={`${styles.bottonBorder} ml-1`}
        placeholder="Status de Integração com Tracking"
        valueExpr="id"
        displayExpr="nome"
        showDropDownButton={true}
        dropDownButtonRender={DropDownImage}
        dataSource={statusIntegracaoOptions}
        openOnFieldClick={true}
        value={selectedItemKeys}
        onValueChanged={onValueChanged}
        onOpenedChange={e => {
          setOpened(e);
        }}
        opened={opened}
        onKeyUp={e => {
          if (opened) e.preventDefault();
          if (
            e.event.originalEvent.code === 'ArrowDown' ||
            e.event.originalEvent.key === 'ArrowDown' ||
            e.event.originalEvent.code === 'Enter' ||
            e.event.originalEvent.key === 'Enter' ||
            e.event.originalEvent.code === 'Space' ||
            e.event.originalEvent.key === 'Space'
          ) {
            setOpened(true);
          }
        }}
        contentRender={() => {
          return (
            <DataGrid 
              dataSource={statusIntegracaoOptions} 
              keyExpr="id" 
              showBorders={false} 
              selectedRowKeys={selectedItemKeys} 
              onSelectionChanged={onSelectedItemKeysChange}
            >
              <Selection mode="single" showCheckBoxesMode={'always'} />
              <FilterRow visible={true} />
              <Scrolling mode="normal" />
              <Column dataField="nome" caption="Nome" />
            </DataGrid>
          );
        }}
      />
      <span className={`${styles[statusCss]}`}>&nbsp;{label}</span>
    </div>
  );
}