import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 0.25rem 1rem;
  border-radius: 4px;
  min-height: 40px;
  min-width: 70px;
  font-weight: 600;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;

  ${({ variant, bgColor }) =>
    variant === 'outlined'
      ? `
    background-color: transparent;
    border: 2px solid ${bgColor || '#552b85'};
    color: ${bgColor || '#552b85'};
  `
      : `
    border: none;
    background-color: ${bgColor || '#552b85'};
    color: white;
  `}

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

    ${({ variant, bgColor }) =>
      variant === 'outlined'
        ? `
      background-color: ${bgColor || '#f0e7ff'};
      color: ${bgColor || '#552b85'};
    `
        : `
      background-color: #6932a8;
    `}
  }
`;
