export const arrayHasContent = arr => {
  if (!Array.isArray(arr)) return false;
  return arr.length > 0 && arr.some(item => item !== null && item !== undefined);
};

const cleanValueToCompare = value => {
  let cleanedValue = value;
  cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9]/g, '');
  cleanedValue = cleanedValue.toLowerCase();
  return cleanedValue;
};

export const filterByMatchingKey = (dataArray, keyToMatch, searchValue) => {
  if (!Array.isArray(dataArray) || typeof keyToMatch !== 'string' || !keyToMatch || typeof searchValue !== 'string') {
    return [];
  }

  return dataArray.filter(item => {
    if (item && typeof item === 'object' && item.hasOwnProperty(keyToMatch)) {
      const itemValue = item[keyToMatch];
      if (typeof itemValue === 'string') {
        const cleanedItemValue = cleanValueToCompare(itemValue);
        const cleanedSearchedItemValue = cleanValueToCompare(searchValue);
        return cleanedItemValue.includes(cleanedSearchedItemValue);
      }
    }
    return false;
  });
};

export const delay = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));

export const debounce = (fn, delay = 1000) => {
  let timeoutId;

  return function(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

export const removeEmptyValues = obj => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== ''));
};

export const areEqualIgnoringAccents = (firstString, secondString) => {
  const normalize = string =>
    string
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase();
  return normalize(firstString) === normalize(secondString);
};

export const formatISODateTime = isoDateTime => {
  if (!isoDateTime) return 'Data Inválida'
  const date = new Date(isoDateTime);
  
  const pad = num => String(num).padStart(2, '0');
  
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export const sumByKey = (array, key = 'valor') => {
  if (!arrayHasContent(array)) return 0;
  return array.reduce((total, item) => total + (item[key] || 0), 0);
};

export const arrayFallback = array => Array.isArray(array) ? array : []

export const handlePDFDownload = (base64String, filename = "file") => {
  const binaryData = atob(base64String);
  
  const binaryArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    binaryArray[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([binaryArray], { type: 'application/pdf' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();

  URL.revokeObjectURL(link.href);
}