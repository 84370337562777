import React, { useEffect } from 'react';
import { CloseButton, Message, StyledAlert } from './styles';

const alertVariants = ['info', 'success', 'warning', 'error'];

const determineVariant = variant => {
  return alertVariants.includes(variant) ? variant : 'info';
};

const Alert = ({ message, isOpen, variant, filled, displayDuration = 5000, onClose = () => {} }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, displayDuration);

      return () => clearTimeout(timer);
    }
  }, [isOpen, displayDuration]);

  if (!isOpen || !message) return null;

  return (
    <StyledAlert variant={determineVariant(variant)} filled={filled}>
      <Message>{message}</Message>
      <CloseButton onClick={onClose} aria-label="Close alert">
        &times;
      </CloseButton>
    </StyledAlert>
  );
};

export default Alert;
