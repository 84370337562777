export const ufOptions = [
  // { id: 0, label: 'Verificar UF' },
  { id: 11, label: 'RO - Rondônia' },
  { id: 12, label: 'AC - Acre' },
  { id: 13, label: 'AM - Amazonas' },
  { id: 14, label: 'RR - Roraima' },
  { id: 15, label: 'PA - Pará' },
  { id: 16, label: 'AP - Amapá' },
  { id: 17, label: 'TO - Tocantins' },
  { id: 21, label: 'MA - Maranhão' },
  { id: 22, label: 'PI - Piauí' },
  { id: 23, label: 'CE - Ceará' },
  { id: 24, label: 'RN - Rio Grande do Norte' },
  { id: 25, label: 'PB - Paraíba' },
  { id: 26, label: 'PE - Pernambuco' },
  { id: 27, label: 'AL - Alagoas' },
  { id: 28, label: 'SE - Sergipe' },
  { id: 29, label: 'BA - Bahia' },
  { id: 31, label: 'MG - Minas Gerais' },
  { id: 32, label: 'ES - Espírito Santo' },
  { id: 33, label: 'RJ - Rio de Janeiro' },
  { id: 35, label: 'SP - São Paulo' },
  { id: 41, label: 'PR - Paraná' },
  { id: 42, label: 'SC - Santa Catarina' },
  { id: 43, label: 'RS - Rio Grande do Sul' },
  { id: 50, label: 'MS - Mato Grosso do Sul' },
  { id: 51, label: 'MT - Mato Grosso' },
  { id: 52, label: 'GO - Goiás' },
  { id: 53, label: 'DF - Distrito Federal' },
  { id: 99, label: 'EXTERIOR' },
];

export const wheeledOptions = [
  { id: '01', label: '01 - Truck' },
  { id: '02', label: '02 - Toco' },
  { id: '03', label: '03 - Cavalo Mecânico' },
  { id: '04', label: '04 - VAN' },
  { id: '05', label: '05 - Utilitário' },
  { id: '06', label: '06 - Outros' },
];

export const bodyworkOptions = [
  { id: '00', label: '00 - Não Aplicado' },
  { id: '01', label: '01 - Aberta' },
  { id: '02', label: '02 - Fechada/Baú' },
  { id: '03', label: '03 - Graneleira' },
  { id: '04', label: '04 - Porta Container' },
  { id: '05', label: '05 - Slider' },
];

export const ownerOptions = [
  { id: '00', label: '00 - TAC Agregado' },
  { id: '01', label: '01 - TAC Independente' },
  { id: '02', label: '02 - Outros' },
];
