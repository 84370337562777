import styled from 'styled-components';

export const StyledCard = styled.div`
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: ${({ marginTop }) => `${marginTop || 0}rem`}!important;
  margin-bottom: ${({ marginBottom }) => `${marginBottom || 0}rem`}!important;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  gap: ${({ gap }) => `${gap || 1}rem`};
  position: relative;

  strong {
    position: absolute;
    top: -0.5rem;
    left: 1rem;
    font-size: 0.85rem;
    background-color: #fff;
  }
`;
