import React from 'react';
import { StyledIconButton } from './styles';
import Tooltip from '../Tooltip';

const IconButton = ({ icon, onClick, tip, ...props }) => {
  if (tip) {
    return (
      <Tooltip text={tip}>
        <StyledIconButton onClick={onClick} {...props}>
          {icon}
        </StyledIconButton>
      </Tooltip>
    );
  }

  return (
    <StyledIconButton onClick={onClick} {...props}>
      {icon}
    </StyledIconButton>
  );
};

export default IconButton;
