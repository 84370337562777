import { getDadosLogin } from '../../../shared/utils/Utils';

const establishmentCode = getDadosLogin().codigoEstabelecimento || 1;

export const formInitialData = {
  estaCod: establishmentCode,
  emprCod: '',
  nome: '',
  cpf: '',
  dataNascimento: '',
  identidade: '',
  orgaoEmissor: '',
  logradouro: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  uf: '',
  cep: '',
  telefone: '',
  email: '',
  site: '',
};
