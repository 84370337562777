import styled from 'styled-components';

export const StyledCheckbox = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 'auto')};

  label {
    margin: 0 !important;
    font-size: 0.85rem !important;
  }

  input {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;
