import styled from 'styled-components';

export const StyledAutocomplete = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  flex: ${({ flex }) => flex || 'auto'};
  position: relative;
`;

export const StyledInputBox = styled.div`
  border: ${({ error }) => `1px solid ${error ? '#f00' : 'rgba(189, 189, 189, 0.5)'}`};
  border-radius: 8px !important;
  position: relative;
  height: 40px;
  display: flex;
  padding: 0.125rem;
  background-color: ${({ disabled }) => (disabled ? '#f5f5f5' : '#fff')};
  color: ${({ disabled }) => (disabled ? '#a1a1a1' : '#000')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

  &:hover {
    border-color: ${({ error, disabled }) => (disabled ? 'rgba(189, 189, 189, 0.5)' : error ? '#f00' : 'rgba(0, 0, 0, 0.7)')};
  }

  &:focus {
    outline: none;
    border-color: ${({ error, disabled }) => (disabled ? 'rgba(189, 189, 189, 0.5)' : error ? '#f00' : 'rgba(0, 0, 0, 0.7)')};
  }

  svg {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

export const StyledInput = styled.input`
  padding: 0 1rem;
  font-size: 0.95rem !important;
  border: none;
  width: 100% !important;

  &::placeholder {
    color: ${({ disabled }) => (disabled ? '#a1a1a1' : '#8e8e8e')};
    font-size: 0.95rem !important;
  }

  text-overflow: ellipsis !important;
`;

export const StyledOptionsBox = styled.div`
  border: 1px solid rgba(189, 189, 189, 0.5);
  border-radius: 8px;
  position: absolute;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  max-height: 50vh;
  overflow-y: auto;
  top: 100%;
  width: 100%;
`;

export const StyledOption = styled.div`
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(189, 189, 189, 0.5);

  &:hover {
    background-color: aliceblue;
  }
`;
