import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';
export class ApiSalvarSolResService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (data) => {
    if(!data)throw new Error('Dados não informado!');
    try {
      //const resp = await this.urlLinxCore.get(`/SolReserva/BuscarSolicitacaoAtual/${estaCod}`);
      const resp = await this.urlLinxCore.post(`/SolReserva/SalvarSolicitacao`, data);

      return resp.statusText === 'OK';
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  };

}
