import styled from 'styled-components';

export const StyledBox = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  gap: ${({ gap }) => `${gap || 0}rem`};
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => `${padding || 0}rem`};
`;
