import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';
export class ApiListarSolResService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (filter = null, listarItens = false) => {
    // let usuario = getDadosLogin();
    // let estaCod = usuario.codigoEstabelecimento;
    if (!filter) {
      filter = {};
    }
    // const query = { ...filter };

    // let u = new URLSearchParams(query).toString();
    // u += `&listarItens=${listarItens ? true : false}`;

    // try {
    // let resp = null;
    /*if (listarItens) resp = await this.urlLinxCore.get(`/ConComodato/ListarItens?${u}`);
      else resp = await this.urlLinxCore.get(`/ConComodato/listar?${u}`);
      return resp.data;*/
    return [];
    // } catch (er) {
    // await new AxiosErrorService().execute(er);
    // }
  };
}
